import { Checkbox, FormControl, FormControlLabel, FormHelperText, TextField } from "@mui/material";

const StepFour = ({ register, errors, control }) => {
    return (
        <div className="application-modal_step" key={1}>
            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Почему хочешь стать волонтером:
                </span>
                <TextField
                    size="small"
                    {...register("motivateText", { required: true })}
                    error={!!errors.motivateText}
                    helperText={errors.motivateText && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Дополнительная информация о тебе:
                </span>
                <TextField
                    size="small"
                    {...register("addInfo")}
                    error={!!errors.addInfo}
                    helperText={errors.addInfo && "Обязательное поле"}
                />
            </FormControl>

            <FormControl className="application-modal_question">
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            {...register("agreeRules", { required: true })}
                        />
                    }
                    label="Я подтверждаю, что ознакомлен(-а) с политикой обработки персональных данных и даю согласие на обработку персональных данных."
                    sx={{
                        "& .MuiFormControlLabel-label": {
                            fontSize: "12px",
                        },
                    }}
                />
                {errors.agreeRules && (
                    <FormHelperText error>Обязательное поле</FormHelperText>
                )}
            </FormControl>
        </div>
    );
};

export default StepFour;
