import background from "../../video/poster.png";
import mp4 from "../../video/about.mp4";
import logoGeneral from "../../img/index/general_logo.png";
import logoBanner from "../../img/index/alfabank/logo.png";
import cardBanner from "../../img/index/alfabank/card.png";
import waveBanner from "../../img/index/alfabank/wave.png";

import CountUp from "react-countup";
import { TypeAnimation } from "react-type-animation";

import { Swiper, SwiperSlide } from "swiper/react";

import AOS from "aos";
import "aos/dist/aos.css";

import "swiper/css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.scss";
import { Pagination, Navigation, Autoplay } from "swiper";

import data from "../../about_zones.json";

import "./style.scss";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import arrowDown from "../../img/about/arrow.svg";
import arrowLeft from "../../img/about/arrow-left.svg";
import arrowRight from "../../img/about/arrow-right.svg";

const About = () => {
    const [zones, setApplications] = useState(data);

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        AOS.init();
    }, []);

    const containerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const container = containerRef.current;
            if (container) {
                const rect = container.getBoundingClientRect();
                const windowHeight =
                    window.innerHeight || document.documentElement.clientHeight;
                if (rect.top <= windowHeight) {
                    setIsVisible(true);
                    window.removeEventListener("scroll", handleScroll);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Check initial visibility
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }, [pathname]);

    const slides = [
        {
            title: "2013",
            subtitle: "Первый фестиваль",
            content: "Проводился на городском стадионе.",
        },
        {
            title: "2014",
            subtitle: "Новые музыкальные направления",
            content: "Увеличивается территория фестиваля.",
        },
        {
            title: "2015",
            subtitle: "Новая локация",
            content:
                "Переезд на центральный пляж Браслава, первые зарубежные артисты.",
        },
        {
            title: "2016",
            subtitle: "Кемпинг. Вторая сцена",
            content:
                "Появляется кемпинг, вторая сцена с альтернативными музыкальными направлениями. Увеличивается общая территория фестиваля.",
        },
        {
            title: "2017",
            subtitle: "Расширение границ",
            content:
                "Изменяется локация кемпинга фестиваля и расширяются его границы.",
        },
        {
            title: "2018",
            subtitle: "Новый формат",
            content:
                "Переходит на двухдневный формат. Изменяется локация кемпинга и расширяются его границы. Открывается третья сцена.",
        },
        {
            title: "2019",
            subtitle: "Новые сцены",
            content:
                "Появляется четвертая сцена. Кемпинг фестиваля переходит на четырехдневный формат.",
        },
        {
            title: "2021",
            subtitle: "Глэмпинг",
            content:
                "Появляется глэмпинг фестиваля. Расширяется территория мероприятия и улучшается инфраструктура.",
        },
        {
            title: "2022",
            subtitle: "Авто garage",
            content:
                "Масштабируется глэмпинг и появляется локация Авто garage. Совершенствуется структура фестиваля.",
        },
        {
            title: "2023",
            subtitle: "Авто camper",
            content:
                "Появляется локация “Авто camper”. Расширяется территория фестиваля, появляется кемпинг бас-туров. Создается площадка на возвышении для гостей на инвалидных колясках.",
        },
        {
            title: "2024",
            subtitle: "Garage camp",
            content:
                "Появляется вторая локация кемпинга ‘Garage camp’. Развивается инфраструктура фестиваля, появляются три дополнительные музыкальные сцены.",
        }
    ];

    const nextElRef = useRef(null);
    const prevElRef = useRef(null);

    const [swiperHistory, setSwiperHistory] = useState({
        isEnd: false,
        isStart: false,
    });

    const swiperRef = useRef(null);
    const [autoplayEnabled, setAutoplayEnabled] = useState(false);

    const handleSwiperInit = (swiper) => {
        const { slides } = swiperRef.current.params;
        const isAutoplayEnabled = slides.length <= 3;
        if (slides.length <= 3) {
            setAutoplayEnabled(isAutoplayEnabled);
            swiper.autoplay.start();
        } else {
            setAutoplayEnabled(isAutoplayEnabled);
            swiper.autoplay.stop();
        }
    };

    return (
        <>
            <div className="about">
                <div className="about_container">
                    <div className="about_wrapper">
                        <div className="about_video_block">
                            <h1 className="about_video_title">
                                Непередаваемая атмосфера, спорт, драйв, музыка и
                                красивые места
                            </h1>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={30}
                                modules={[Autoplay]}
                                className="about_video_short"
                                loop={true}
                                autoplay={true}
                                breakpoints={{
                                    370: {
                                        slidesPerView: 1,
                                        autoplay: {
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        },
                                    },
                                    574: {
                                        slidesPerView: 2,
                                        autoplay: {
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        },
                                    },
                                    775: {
                                        slidesPerView: 3,
                                        autoplay: {
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        },
                                    },
                                    880: {
                                        slidesPerView: 3,
                                        autoplay: {
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        },
                                    },
                                    1024: {
                                        autoplay: false,
                                        slidesPerView: 3,
                                        
                                    },
                                    1300: {
                                        autoplay: false,
                                        slidesPerView: 3,
                                    },
                                }}>
                                <SwiperSlide className="about_video_short_item">
                                    <span className="about_video_short_item_number">
                                        <CountUp
                                            duration={3}
                                            start={1}
                                            end={12}
                                            delay={0.3}
                                        />{" "}
                                        лет
                                    </span>
                                    <span className="about_video_short_item_description">
                                        фестивалю
                                    </span>
                                </SwiperSlide>
                                {/* <SwiperSlide className="about_video_short_item">
                                    <span className="about_video_short_item_number">
                                        ~
                                        <CountUp
                                            duration={2.75}
                                            start={1}
                                            end={30}
                                            delay={0.8}
                                        />{" "}
                                        тыс
                                    </span>
                                    <span className="about_video_short_item_description">
                                        посетителей в 2023 г.
                                    </span>
                                </SwiperSlide> */}
                                <SwiperSlide className="about_video_short_item">
                                    <span className="about_video_short_item_number">
                                        <CountUp
                                            duration={2.75}
                                            start={0}
                                            end={2}
                                            delay={1.2}
                                        />{" "}
                                        дня
                                    </span>
                                    <span className="about_video_short_item_description">
                                        работы фестиваля
                                    </span>
                                </SwiperSlide>
                                <SwiperSlide className="about_video_short_item">
                                    <span className="about_video_short_item_number">
                                        <CountUp
                                            duration={2.75}
                                            start={1}
                                            end={4}
                                            delay={1.6}
                                        />{" "}
                                        дня
                                    </span>
                                    <span className="about_video_short_item_description">
                                        работы кемпинга
                                    </span>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                        <video
                            className="about_video"
                            loop
                            muted
                            autoPlay
                            playsInline
                            preload="metadata"
                            >
                            <source src={mp4} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
            <div
                className="about-scroll"
                style={isVisible ? { opacity: 0, height: 0 } : {}}>
                <div className="about-container">
                    <div className="about-scroll_wrapper">
                        <span className="about-scroll_text">
                            Листай дальше, чтобы узнать о фестивале
                        </span>
                        <img src={arrowDown} />
                    </div>
                </div>
            </div>
            <div className="short-description">
                <div className="about-container">
                    <div className="short-description_wrapper">
                        {isVisible && (
                            <TypeAnimation
                                sequence={[
                                    `Фестиваль проводится ежегодно летом на территории Национального парка «Браславские озера» на северо-западе страны.\n\nПриехав на Viva Braslav, тебе открывается много интересных локаций, развлечений и достопримечательностей. Наполни свой отдых незабываемыми воспоминаниями и впечатлениями!`,
                                    100,
                                ]}
                                wrapper="span"
                                className="short-description_text"
                                speed={90}
                                style={{ whiteSpace: "pre-line" }}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="festival-zones" ref={containerRef}>
                <div className="about-container">
                    <div className="festival-zones_wrapper">
                        {zones.map((item, index) => {
                            return (
                                <div
                                    className="festival-zones_item"
                                    data-aos="fade-up">
                                    <h4 className="festival-zones_item_title">
                                        {item.title}
                                    </h4>
                                    <span
                                        className="festival-zones_item_description"
                                        data-aos="fade-up"
                                        dangerouslySetInnerHTML={{
                                            __html: item.description,
                                        }}></span>
                                    <span
                                        className="festival-zones_item_additional"
                                        data-aos="fade-up"
                                        dangerouslySetInnerHTML={{
                                            __html: item.short,
                                        }}></span>
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={30}
                                        navigation={true}
                                        modules={[ Autoplay, Navigation]} 
                                        className="festival-zones_item_slider"
                                        loop={true}
                                        autoplay={{
                                            delay: 3000 + index * 1000,
                                            disableOnInteraction: false,
                                        }}
                                        breakpoints={{
                                            370: {
                                                slidesPerView: 1,
                                            },
                                            574: {
                                                slidesPerView: 2,
                                            },
                                            775: {
                                                slidesPerView: 3,
                                            },
                                            880: {
                                                slidesPerView: 3,
                                            },
                                            1024: {
                                                slidesPerView: 4,
                                            },
                                            1300: {
                                                slidesPerView: 4,
                                            },
                                        }}>
                                        {item.gallery?.map(
                                            (slide: string, index) => {
                                                return (
                                                    <SwiperSlide
                                                        key={
                                                            "slide_" +
                                                            item.id +
                                                            "_" +
                                                            index
                                                        }
                                                        className="festival-zones_item_slider_slide">
                                                        <img
                                                            src={
                                                                "https://vivabraslav.by/" +
                                                                slide
                                                            }
                                                            className="festival-zones_item_slider_slide_img"
                                                            alt="swiper item"
                                                        />
                                                    </SwiperSlide>
                                                );
                                            }
                                        )}
                                    </Swiper>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="about-history">
                <div className="about-container">
                    <div className="about-history_wrapper">
                        <h4 className="about-history_title" data-aos="fade-up">
                            История фестиваля
                        </h4>
                        <span
                            className="about-history_description"
                            data-aos="fade-up">
                            Смотри, как развивается фестиваль и с каждым годом
                            улучшает свою инфоструктуру!
                        </span>
                        <Swiper
                            navigation={{
                                nextEl: nextElRef.current,
                                prevEl: prevElRef.current,
                            }}
                            data-aos="fade-up"
                            modules={[Navigation]}
                            slidesPerView={1}
                            onSwiper={(swiper) =>
                                setSwiperHistory({
                                    isEnd: swiper.isEnd,
                                    isStart: swiper.isBeginning,
                                })
                            }
                            onSlideChange={(swiper1) =>
                                setSwiperHistory({
                                    isEnd: swiper1.isEnd,
                                    isStart: swiper1.isBeginning,
                                })
                            }
                            className=""
                            breakpoints={{
                                370: {
                                    slidesPerView: 1,
                                },
                                574: {
                                    slidesPerView: 2,
                                },
                                775: {
                                    slidesPerView: 3,
                                },
                                880: {
                                    slidesPerView: 3,
                                },
                                1024: {
                                    slidesPerView: 4,
                                },
                                1300: {
                                    slidesPerView: 4,
                                },
                            }}>
                            {slides.map((item, index) => {
                                return (
                                    <SwiperSlide className="about-history_item">
                                        <div
                                            className={
                                                index + 1 == slides.length
                                                    ? "about-history_item_line-end"
                                                    : "about-history_item_line"
                                            }></div>
                                        <div className="about-history_item_circle"></div>
                                        <span className="about-history_item_title">
                                            {item.title}
                                        </span>
                                        <span className="about-history_item_subtitle">
                                            {item.subtitle}
                                        </span>
                                        <span className="about-history_item_text">
                                            {item.content}
                                        </span>
                                    </SwiperSlide>
                                );
                            })}

                            <div
                                className="about-history_back"
                                ref={prevElRef}
                                style={
                                    swiperHistory.isStart ? { opacity: 0 } : {}
                                }>
                                <img src={arrowLeft} />
                            </div>
                            <div
                                className="about-history_next"
                                ref={nextElRef}
                                style={
                                    swiperHistory.isEnd ? { opacity: 0 } : {}
                                }>
                                <img src={arrowRight} />
                            </div>
                        </Swiper>
                    </div>
                </div>
            </div>
            <div className="festival-video">
                <div className="festival-video_container">
                    <div className="festival-video_wrapper">
                        <h4 className="festival-video_title" data-aos="fade-up">
                            Исследуй Viva Braslav
                        </h4>
                        <span
                            className="festival-video_description"
                            data-aos="fade-up">
                            Посмотри клипы фестиваля, чтобы прочувствовать
                            атмосферу и узнать больше о впечатлениях, которые
                            тебя ожидают!
                        </span>
                        <div className="festival-video_wrap"> 
                            <div className="festival-video_item">
                                <iframe
                                    width="1000"
                                    height="500"
                                    src="https://www.youtube.com/embed/xr1jVXhH388"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </div>
                            <div className="festival-video_item">
                                <iframe
                                    width="1000"
                                    height="500"
                                    src="https://www.youtube.com/embed/_THYLXwLO2A"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;
