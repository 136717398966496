import React, { useState, useEffect } from "react";
import {
    Button,
    TextField,
    FormControl,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Select,
    MenuItem,
    Radio,
    RadioGroup,
    ListItemText,
} from "@mui/material";
import axios from "axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import PageTitle from "../../Components/pageTitle";
import "../returns/style.scss";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

type Inputs = {
    firstName: string;
    email: string;
    ticketCategories: string[];
    disabilityGroup?: string;
    healthLossDegree?: string;
    accompanyingTicket: boolean;
    documentFront: File[];
    documentBack: File[];
    userType: "adult" | "child";
    agree: boolean;
    event: "vb";
    appliactionType: "social";
};

const SocialTickets = () => {
    const {
        control,
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
        reset,
    } = useForm<Inputs>({
        defaultValues: {
            firstName: "",
            email: "",
            accompanyingTicket: false,
            userType: "adult",
            ticketCategories: [],
            agree: false,
            event: "vb",
            appliactionType: "social",
        },
    });

    const optionsAdult = [{ label: "Стандарт 2 дня", value: "Стандарт 2 дня" }];

    const optionsChild = [{ label: "Стандарт 2 дня", value: "Стандарт 2 дня" }];

    const [selectedFilesFront, setSelectedFilesFront] = useState<File[]>([]);
    const [selectedFilesBack, setSelectedFilesBack] = useState<File[]>([]);
    const [stateForm, setStateForm] = useState({
        loader: false,
        isFinal: false,
        message: "",
    });

    const userType = watch("userType");
    const disabilityGroup = watch("disabilityGroup");
    const formData = watch();

    useEffect(() => {
        console.log(formData);
    }, [formData]);

    useEffect(() => {
        if (userType === "adult") {
            setValue("healthLossDegree", undefined);
        } else {
            setValue("disabilityGroup", undefined);
        }
    }, [userType, setValue]);

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData = new FormData();
        formData.append("data", JSON.stringify(data));
        formData.append('g-recaptcha-response', "test");
        selectedFilesFront.forEach((file, index) => {
            formData.append(`file_${index}`, file);
        });
        selectedFilesBack.forEach((file, index) => {
            formData.append(`file_${selectedFilesFront.length + index}`, file);
        });

        setStateForm({ ...stateForm, loader: true });

        const config = {
            method: "post",
            url: "https://vivabraslav.by/prod-api/applications/index.php",
            data: formData,
        };

        axios(config)
            .then(function (response) {
                setStateForm({
                    loader: false,
                    isFinal: true,
                    message: response.data.message_user,
                });
                reset();
                setSelectedFilesFront([]);
                setSelectedFilesBack([]);
            })
            .catch(function (error) {
                setStateForm({
                    loader: false,
                    isFinal: true,
                    message: error.message_user,
                });
            });
    };

    const handleFileSelectFront = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const files = event.target.files;
        if (files) {
            setSelectedFilesFront(Array.from(files));
        }
    };

    const handleFileSelectBack = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const files = event.target.files;
        if (files) {
            setSelectedFilesBack(Array.from(files));
        }
    };

    const handleCloseModalFinal = () => {
        setStateForm({ ...stateForm, isFinal: false });
    };

    const [ticketOptions, setTicketOptions] = useState(optionsAdult);

    useEffect(() => {
        if (userType === "adult") {
            setTicketOptions(optionsAdult);
        } else {
            setTicketOptions(optionsChild);
        }
        setValue("ticketCategories", []);
        setValue("disabilityGroup", "");
        setValue("healthLossDegree", "");
    }, [userType, setValue]);

    return (
        <>
            <PageTitle title="Социальные билеты" subtitle="" />
            <div className="returns">
                <div className="returns_container">
                    <div className="returns_wrapper">
                        <h1 className="returns_title">Информация</h1>
                        <span className="returns_info">
                            На Фестиваль Viva Braslav предоставляются Социальные
                            билеты категорий «Стандарт 2 дня»
                            <br />
                            <br />
                            -Лицам с I группой инвалидности, а также его
                            сопровождающему (не более одного человека).
                            <br />
                            -Лицам со II группой инвалидности. (Сопровождающий
                            оплачивает стоимость билетов в полном размере).
                            <br />
                            -Лицам, не достигшим 18 лет, со степенью утраты
                            здоровья (1-я, 2-я, 3-я и 4-я), а также их
                            сопровождающему (не более одного человека).
                            <br />
                            <br />
                            <br />
                            Получение социального билета осуществляется на
                            безвозмездной основе, и он предоставляется только
                            лицам, имеющим документально подтвержденное право на
                            льготу.
                            <br />
                            <br />
                            Для получения билета необходимо ознакомиться с
                            правилами посещения фестиваля (ссылка) и заполнить
                            заявку, указав личные данные, и прикрепить документ,
                            подтверждающий право на льготу.
                            <br />
                            <br />
                            Все поданные заявки проходят проверку. После
                            успешного рассмотрения билет будет отправлен на
                            адрес электронной почты, указанный в заявке.
                            <br />
                            <br />
                            Взрослым с утратой здоровья предоставляется
                            бесплатная услуга по наблюдению за
                            автомототранспортом в специально отведенной локации.
                            Для получения специального стикера «Автогараж»,
                            который размещается на лобовом стекле, необходимо
                            предоставить документ, подтверждающий право на
                            льготу на пункте пропуска данной локации.
                        </span>
                        <h1 className="returns_title">
                            Получить социальный билет
                        </h1>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="returns_form">
                            <TextField
                                {...register("appliactionType")}
                                name="appliactionType"
                                inputProps={{ type: "hidden" }}
                                defaultValue="social"
                                style={{ display: "none" }}
                            />
                            <TextField
                                {...register("event")}
                                name="event"
                                inputProps={{ type: "hidden" }}
                                defaultValue="vb"
                                style={{ display: "none" }}
                            />
                            <FormControl
                                component="fieldset"
                                style={{ marginBottom: "10px", width: "100%" }}
                                >
                                <Controller
                                    name="userType"
                                    control={control}
                                    defaultValue="adult"
                                    rules={{ required: true }}
                                    // {...register("userType")}
                                    render={({ field }) => (
                                        <RadioGroup row {...field}>
                                            <FormControlLabel
                                                value="adult"
                                                control={
                                                    <Radio
                                                        sx={{
                                                            "&.Mui-checked": {
                                                                color: "#000",
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Взрослый"
                                            />
                                            <FormControlLabel
                                                value="child"
                                                control={
                                                    <Radio
                                                        sx={{
                                                            "&.Mui-checked": {
                                                                color: "#000",
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Ребенок до 18 лет"
                                            />
                                        </RadioGroup>
                                    )}
                                />
                            </FormControl>

                            <FormControl
                                fullWidth
                                style={{ marginBottom: "10px" }}>
                                <Controller
                                    name="ticketCategories"
                                    control={control}
                                    rules={{
                                        required: "Выберите категорию билета",
                                    }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            displayEmpty
                                            multiple
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return "Выберите категорию";
                                                }
                                                return selected.join(", ");
                                            }}
                                            size="small"
                                            error={!!errors.ticketCategories}
                                            style={{
                                                backgroundColor: "#fff",
                                                borderRadius: "10px",
                                            }}>
                                            {ticketOptions.map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}>
                                                    <Checkbox
                                                        size="small"
                                                        checked={
                                                            field.value.indexOf(
                                                                option.value
                                                            ) > -1
                                                        }
                                                    />
                                                    <ListItemText
                                                        primary={option.label}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {errors.ticketCategories && (
                                    <span
                                        className="application-modal_question_error"
                                        style={{ color: "#000" }}>
                                        Выберите категорию билета
                                    </span>
                                )}
                            </FormControl>
                            <TextField
                                size="small"
                                fullWidth
                                placeholder="Имя и Фамилия"
                                {...register("firstName", {
                                    required:
                                        "Это поле обязательно для заполнения",
                                })}
                                error={!!errors.firstName}
                                helperText={
                                    errors.firstName
                                        ? errors.firstName.message
                                        : ""
                                }
                                style={{ marginBottom: "10px" }}
                                InputProps={{
                                    style: {
                                        background: "#fff",
                                        borderRadius: "10px",
                                    },
                                }}
                            />

                            <TextField
                                size="small"
                                fullWidth
                                placeholder="Эл. почта"
                                {...register("email", {
                                    required:
                                        "Это поле обязательно для заполнения",
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message:
                                            "Введите корректный email адрес",
                                    },
                                })}
                                error={!!errors.email}
                                helperText={
                                    errors.email ? errors.email.message : ""
                                }
                                style={{ marginBottom: "10px" }}
                                InputProps={{
                                    style: {
                                        background: "#fff",
                                        borderRadius: "10px",
                                    },
                                }}
                            />

                            {userType === "adult" ? (
                                <FormControl
                                    style={{
                                        marginBottom: "10px",
                                        width: "100%",
                                    }}>
                                    <Select
                                        defaultValue=""
                                        {...register("disabilityGroup", {
                                            required:
                                                "Это поле обязательно для заполнения",
                                        })}
                                        displayEmpty
                                        error={!!errors.disabilityGroup}
                                        style={{
                                            background: "#fff",
                                            borderRadius: "10px",
                                        }}
                                        size="small"
                                        key="adult">
                                        <MenuItem value="">
                                            Выберите группу инвалидности
                                        </MenuItem>
                                        <MenuItem value="I">I группа</MenuItem>
                                        <MenuItem value="II">
                                            II группа
                                        </MenuItem>
                                    </Select>
                                    {errors.disabilityGroup && (
                                        <span style={{ color: "#f44336" }}>
                                            {errors.disabilityGroup.message}
                                        </span>
                                    )}
                                </FormControl>
                            ) : (
                                <FormControl
                                    style={{
                                        marginBottom: "10px",
                                        width: "100%",
                                    }}>
                                    <Select
                                        defaultValue=""
                                        {...register("healthLossDegree", {
                                            required:
                                                "Это поле обязательно для заполнения",
                                        })}
                                        displayEmpty
                                        error={!!errors.healthLossDegree}
                                        style={{
                                            background: "#fff",
                                            borderRadius: "10px",
                                        }}
                                        size="small"
                                        key="child">
                                        <MenuItem value="">
                                            Выберите степень утраты здоровья
                                        </MenuItem>
                                        <MenuItem value="1">1 степень</MenuItem>
                                        <MenuItem value="2">2 степень</MenuItem>
                                        <MenuItem value="3">3 степень</MenuItem>
                                        <MenuItem value="4">4 степень</MenuItem>
                                    </Select>
                                    {errors.healthLossDegree && (
                                        <span style={{ color: "#f44336" }}>
                                            {errors.healthLossDegree.message}
                                        </span>
                                    )}
                                </FormControl>
                            )}

                            {userType === "adult" &&
                                disabilityGroup === "I" && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                {...register(
                                                    "accompanyingTicket"
                                                )}
                                                sx={{
                                                    color: "#000",
                                                    "&.Mui-checked": {
                                                        color: "#000",
                                                    },
                                                }}
                                            />
                                        }
                                        label="Необходим дополнительный билет для сопровождающего"
                                        sx={{
                                            "& .MuiFormControlLabel-label": {
                                                fontSize: "12px",
                                                color: "#000",
                                            },
                                        }}
                                    />
                                )}
                            <span
                                className="returns_info"
                                style={{ margin: "20px 0 10px 0" }}>
                                Приложите документ, подтверждающий право на
                                льготу
                            </span>
                            <Button
                                style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    background: "#fff",
                                    color: "#000",
                                    marginBottom: "15px",
                                }}
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}>
                                {selectedFilesFront.length > 0
                                    ? `Загружено ${selectedFilesFront.length} файлов`
                                    : "Загрузить лицевую сторону"}
                                <VisuallyHiddenInput
                                    type="file"
                                    multiple
                                    accept="application/pdf,image/*"
                                    onChange={handleFileSelectFront}
                                />
                            </Button>
                            <Button
                                style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    background: "#fff",
                                    color: "#000",
                                    marginBottom: "15px",
                                }}
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}>
                                {selectedFilesBack.length > 0
                                    ? `Загружено ${selectedFilesBack.length} файлов`
                                    : "Загрузить обратную сторону"}
                                <VisuallyHiddenInput
                                    type="file"
                                    multiple
                                    accept="application/pdf,image/*"
                                    onChange={handleFileSelectBack}
                                />
                            </Button>
                            <FormControl className="application-modal_question">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            {...register("agree", {
                                                required: true,
                                            })}
                                            sx={{
                                                color: "#000", // Цвет рамки и галочки в неактивном состоянии
                                                "&.Mui-checked": {
                                                    color: "#000", // Цвет рамки и галочки в активном состоянии
                                                },
                                            }}
                                        />
                                    }
                                    label="Я подтверждаю, что ознакомлен(-а) с политикой обработки персональных данных и даю согласие на обработку персональных данных."
                                    sx={{
                                        "& .MuiFormControlLabel-label": {
                                            fontSize: "12px",
                                            color: "#000",
                                        },
                                    }}
                                />
                                {errors.agree && (
                                    <span
                                        className="application-modal_question_error"
                                        style={{ color: "#000" }}>
                                        Это поле обязательно для заполнения
                                    </span>
                                )}
                            </FormControl>

                            <button type="submit" className="returns_form_send">
                                <div
                                    className="loader"
                                    style={
                                        stateForm.loader
                                            ? { display: "block" }
                                            : { display: "none" }
                                    }></div>
                                <span
                                    style={
                                        !stateForm.loader
                                            ? { display: "block" }
                                            : { display: "none" }
                                    }>
                                    Отправить
                                </span>
                            </button>
                        </form>

                        <Dialog
                            open={stateForm.isFinal}
                            onClose={handleCloseModalFinal}>
                            <DialogContent>
                                <DialogContentText>
                                    {stateForm.message}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleCloseModalFinal}
                                    autoFocus>
                                    Закрыть
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SocialTickets;
