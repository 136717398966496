import "../style.scss";

interface IContactItem {
    title: string;
    subtitle: string;
    icon: string;
    iconColor: string;
    data?: {
        type: string;
        linkText: string;
    }[];
}

const ContactItem = ({ title, subtitle, icon, iconColor, data }: IContactItem) => {

    return (
        <div className="contacts_right_item">
            <div className="contacts_right_item_image" style={{background: iconColor}}>
                <img
                    src={icon}
                    alt={title}
                    className="contacts_right_item_image_img"
                />
            </div>
            <div className="contacts_right_item_details">
                <h5 className="contacts_right_item_details_title">{title}</h5>
                <span className="contacts_right_item_details_subtitle">
                    {subtitle}
                </span>
                <div className="contacts_right_item_details_link-block">
                    {
                        data.map((item, index) => {
                            return (
                                <div key={index}>
                                    <span className="contacts_right_item_details_link-block_name">
                                        {item.type === "email" ? "Email: " : "Телефон: "}
                                    </span>
                                    <a
                                        href={
                                            item.type === "email"
                                                ? `mailto:${item.linkText}`
                                                : `tel:${item.linkText}`
                                        }
                                        className="contacts_right_item_details_link-block_link">
                                        {item.linkText}
                                    </a>
                                </div>
                            )
                        })
                    }
                   
                </div>
            </div>
        </div>
    );
};

export default ContactItem;
