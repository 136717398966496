import { useState, useEffect } from "react";
import { Gradient } from "whatamesh";
import './style.scss';

const Background = () => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            const gradient = new Gradient();
            gradient?.initGradient("#gradient-canvas");
        }
    }, [isMounted]);

    return (
        <div className="background">
            <canvas
                id="gradient-canvas"
                data-transition-in=""
                data-js-darken-top={null}
            />
        </div>
    );
};

export default Background;
