import { useAppDispatch } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
    closeModalBonus,
    closeModalMore,
    openModalBuyFromMore,
} from "../../../redux/slices/ticketsModalSlice";
import "./style.scss";

const ModalBonus = () => {
    const dispatch = useAppDispatch();

    const handleClose = () => {
        dispatch(closeModalBonus());
    };

    return (
        <div className="modal-bonus">
            <div className="modal-bonus_container">
                <div className="modal-bonus_wrapper">
                    <p className="modal-bonus_text">
                        Альфа Бонус — программа лояльности Альфа&nbsp;Банка с
                        начислением бонусов за покупки. Бонусы можно менять на
                        деньги, направлять на благотворительность или оплачивать
                        ими услуги в ЕРИП.<br/>1&nbsp;бонус&nbsp;=&nbsp;1&nbsp;BYN. <br/><br/>Оплачивайте покупку
                        билетов картами Visa «Пакета решений» и возвращайте
                        бонусами 10% от суммы купленных билетов. Так можно
                        получить до 50&nbsp;Альфа&nbsp;Бонусов!<br/><br/>Для получения более
                        подробной информации и ознакомления со всеми
                        преимуществами, которыми ты можешь воспользоваться на
                        фестивале имея карту Visa&nbsp;Альфа&nbsp;Банк.
                    </p>
                </div>
            </div>
            <div
                className="modal-bonus_bg"
                onClick={() => {
                    handleClose();
                }}></div>
        </div>
    );
};

export default ModalBonus;
