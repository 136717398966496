import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logoGeneral from "../../img/index/general_logo.png";
import cardBanner from "../../img/index/alfabank/cards.png";
import waveBanner from "../../img/index/alfabank/graffiti.png";
import graffitiLeft from "../../img/index/alfabank/graffiti-left.png";
import background from "../../video/background2.jpg";
import mp4 from "../../video/back2025.mp4";
import "./style.scss";
import "./alfabank.scss";

import ticketIcon from "../../img/index/buttons/tickets.png";
import campIcon from "../../img/index/buttons/camping.png";
import routeIcon from "../../img/index/buttons/routes.png";

import playIcon from "../../video/Play.svg";
import pauseIcon from "../../video/Pause.svg";

const Index = () => {
    const { pathname } = useLocation();
    const [isPaused, setIsPaused] = useState(true);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, [pathname]);

    const getVideo = () => {
        return document.querySelector(".main_video") as HTMLVideoElement;
    };

    const handleVideoPlayPause = () => {
        const video = getVideo();
        if (video.paused) {
            video.play();
            setIsPaused(false);
        } else {
            video.pause();
            setIsPaused(true);
        }
    };

    useEffect(() => {
        const video = getVideo();
        const handlePause = () => setIsPaused(true);
        const handlePlay = () => setIsPaused(false);

        video.addEventListener("pause", handlePause);
        video.addEventListener("play", handlePlay);

        return () => {
            video.removeEventListener("pause", handlePause);
            video.removeEventListener("play", handlePlay);
        };
    }, []);

    return (
        <>
            <div className="main">
                <div className="main_container">
                    <div className="main_grid">
                        <div className="main_wrapper">
                            <div className="main_video_block">
                                <h1 className="main_video_title">25-27 июля</h1>
                                <h1 className="main_video_text">
                                    Viva Braslav 2025
                                </h1>
                                <Link
                                    to="/tickets"
                                    className="main_video_ticket">
                                    Купить билеты
                                </Link>
                                <div className="main_general">
                                    <span className="main_general_text">
                                        Генеральный партнёр
                                    </span>
                                    <img
                                        src={logoGeneral}
                                        alt="Alfabank"
                                        className="main_general_img"
                                    />
                                </div>
                            </div>
                            <video
                                className="main_video"
                                loop
                                muted
                                autoPlay
                                playsInline
                                preload="metadata"
                                poster={background}>
                                <source src={mp4} type="video/mp4" />
                            </video>
                            <div className="main_video_controls">
                                <div
                                    className="main_video_controls_button"
                                    onClick={handleVideoPlayPause}>
                                    <img
                                        src={isPaused ? playIcon : pauseIcon}
                                        alt="Play"
                                        className="main_video_controls_button_icon"
                                    />
                                </div>
                            </div>
                        </div>

                        <Link to="/tickets" className="main_box b"></Link>
                        <Link to="/tickets" className="main_box c">
                            <span className="main_box_title">Билеты</span>
                            <span className="main_box_text">Все категории</span>
                            <img
                                src={ticketIcon}
                                alt=""
                                className="main_box_icon"
                            />
                        </Link>
                        <Link to="/places" className="main_box d">
                            <span className="main_box_title">Проживание</span>
                            <span className="main_box_text">
                                Варианты размещения
                            </span>
                            <img
                                src={campIcon}
                                alt=""
                                className="main_box_icon"
                            />
                        </Link>
                        <Link to="/route" className="main_box e">
                            <span className="main_box_title">
                                Как добраться
                            </span>
                            <span className="main_box_text">
                                Всё, что нужно знать
                            </span>
                            <img
                                src={routeIcon}
                                alt=""
                                className="main_box_icon"
                            />
                        </Link>

                        <section className="alfabank-promo">
                            <div className="alfabank-promo_container">
                                <div className="alfabank-promo_wrapper">
                                    <h3 className="alfabank-promo_title_viva">
                                        Viva
                                    </h3>
                                    <h3 className="alfabank-promo_title_bonus">
                                        Бонус
                                    </h3>
                                    <span className="alfabank-promo_text">
                                        Получайте бонусы за билеты при оплате картами visa альфа банка
                                    </span>
                                    <a
                                        href="https://www.alfabank.by/viva-braslav/?utm_source=website&utm_medium=vivabraslav.by&utm_campaign=alfabonus_vivabraslav&utm_content=banner"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="alfabank-promo_btn">
                                        Узнать больше
                                    </a>
                                    <div className="alfabank-promo_card">
                                        <img
                                            src={cardBanner}
                                            alt="Alfabank card"
                                            className="alfabank-promo_card_img"
                                        />
                                    </div>
                                    <div className="alfabank-promo_graffiti-left">
                                        <img
                                            src={graffitiLeft}
                                            alt="Alfabank wave"
                                            className="alfabank-promo_graffiti-left_img"
                                        />
                                    </div>
                                    <div className="alfabank-promo_wave">
                                        <img
                                            src={waveBanner}
                                            alt="Alfabank wave"
                                            className="alfabank-promo_wave_img"
                                        />
                                    </div>
                                    <div className="alfabank-promo_back-text">
                                        <span className="alfabank-promo_back-text_text">
                                            alfaalfa alfaalfa
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
