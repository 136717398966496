import { createSlice } from '@reduxjs/toolkit';
import { api } from '../api';

export interface UserState {
  tickets: string[],
  // eslint-disable-next-line
  selected: any,
  isOpenModalMore: boolean;
  isOpenModalBuy: boolean;
  isOpenModalBonus: boolean;
  isOpenConfirm: boolean;
}

const initialState: UserState = {
  tickets: [],
  selected: {
    id: null,
    title: "",
    description: "",
    color: "",
    path_photo: "",
    include: [],
    attention: [],
    footnotes: [],
    description_modal: "",
    operator: "",
    event_id: "",
    ticket_id: "",
    photo_modal: [],
    price: "99.95",
    old_prices: [],
    next_prices: [],
    status: "",
    mode: "",
    terms: null,
    description_list: []
  },
  isOpenModalMore: false,
  isOpenModalBuy: false,
  isOpenModalBonus: false,
  isOpenConfirm: false,
};

const ticketsModalSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    openModalMore(state, action) {
      const category = state.tickets[action.payload.category_id - 1];
      if (category) {
        const ticket = category['items'][action.payload.ticket_id - 1];
        if (ticket) {
          state.selected = ticket;
          state.isOpenModalMore = true;
          document.body.classList.add('body-lock');
        }
      }
    },
    openModalBuy(state, action) {
      const category = state.tickets[action.payload.category_id - 1];
      if (category) {
        const ticket = category['items'][action.payload.ticket_id - 1];
        if (ticket) {
          state.selected = ticket;
          state.isOpenModalBuy = true;
          document.body.classList.add('body-lock');
        }
      }
    },
    openModalBonus(state) {
      state.isOpenModalBonus = true;
      document.body.classList.add('body-lock');
    },
    openModalBuyFromMore(state) {
      state.isOpenModalMore = false;
      state.isOpenModalBuy = true;
    },
    closeModalMore(state) {
      state.isOpenModalMore = false;
      document.body.classList.remove('body-lock');
    },
    closeModalBonus(state) {
      state.isOpenModalBonus = false;
      document.body.classList.remove('body-lock');
    },
    closeModalBuy(state) {
      state.isOpenConfirm = false;
      state.selected = {operator: "ticketok", event_id: 0, ticket_id: 0};
      state.isOpenModalBuy = false;
      document.body.classList.remove('body-lock');
    },
    openConfirm(state) {
      state.isOpenConfirm = !state.isOpenConfirm;
    },
    openFinalTicketok(state) {
      state.isOpenModalBuy = true;
    }
  },

  extraReducers: (builder) => {
    builder
    .addMatcher(
      api.endpoints.getTickets.matchFulfilled,
      (state, { payload }) => {
        state.tickets = payload.tickets;
      }
    )
    .addMatcher(
      api.endpoints.getTicketsTest.matchFulfilled,
      (state, { payload }) => {
        state.tickets = payload.tickets;
      }
    )
  },
});

export const {
  openModalMore,
  openModalBuy,
  closeModalMore,
  closeModalBuy,
  openConfirm,
  openFinalTicketok,
  openModalBuyFromMore,
  openModalBonus,
  closeModalBonus
} = ticketsModalSlice.actions;

export default ticketsModalSlice.reducer;
