import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "https://vivabraslav.by/prod-api",
    }),

    endpoints: (builder) => ({
        getTickets: builder.query<any, void>({
            query: () => "/tickets-data/data.json",
        }),
        getApplications: builder.query<any, void>({
            query: () => "/applications/data.json",
        }),
        getProgram: builder.query<any, void>({
            query: () => "/program/data.json",
        }),
        getTicketsTest: builder.query<any, void>({
            query: () => "/tickets-data/data_test_qa.json",
        }),
    }),
});

export const {
    useGetTicketsQuery,
    useGetProgramQuery,
    useGetTicketsTestQuery,
    useGetApplicationsQuery,
} = api;
