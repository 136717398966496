import TorgForm from "../TorgForm";

import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';

import { useAppDispatch } from '../../../app/hooks';
import { closeModal } from '../../../redux/slices/applicationsSlice';
import EntertainmentsForm from "../EntertainmentsForm";
import ArtistsForm from "../ArtistsForm";
import VolForm from "../VolForm";
import WebVolForm from "../WebVolForm";
import PhotoVideoForm from "../PhotoVideoForm";
import SMIForm from "../SMIForm";
import AlfabankForm from "../Alfabank";
import SportForm from "../SportForm";
import StreetballForm from "../Streetball";
import VolleyballForm from "../Voleyball";
import WaterRunning from "../WaterRunning";
import WaterRunForm from "../WaterRun";
import MinigolfForm from "../MinigolfForm";
import GoroshekForm from "../GoroshekForm";

const FormModal = () => {

  const { selectedForm } = useAppSelector((state: RootState) => state.applicationsStore);
  const dispatch = useAppDispatch();

  if (!selectedForm) {
    return(
      <div className="application-modal">
          <div className="application-modal_container">
          </div>
      </div>
    )
  }

    const returnForm = () => {
        switch (selectedForm) {
            case 1:
                return(<TorgForm/>);
            case 2:
                return(<EntertainmentsForm/>);
            case 3:
                return(<ArtistsForm/>);   
            case 4:
                return(<PhotoVideoForm />);   
            case 5:
                return(<VolForm/>);  
            case 6:
                return(<SportForm/>);  
            case 61:
                return(<StreetballForm />);   
            case 62:
                return(<VolleyballForm />);    
            case 63:
                return(<WaterRunForm />);  
            case 64:
                return(<MinigolfForm />);          
            case 7: 
                return(<WebVolForm />);  
            case 8:
                return(<SMIForm />); 
            case 9:
                return(<AlfabankForm />);    
            case 10: 
                return(<GoroshekForm />);
                           
            default:
                break;
        }
    }
    
    return(
        <div className="application-modal">
            <div className="application-modal_container">
                {returnForm()}
            </div>
            <div className="application-modal_bg" onClick={()=>dispatch(closeModal())}></div>
        </div>
    );
}

export default FormModal;