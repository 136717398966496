import React from "react";
import { Controller } from "react-hook-form";
import { FormControl, FormControlLabel, ListItem, ListItemButton, ListItemIcon, ListItemText, Radio, RadioGroup, Typography } from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

const StepOne = ({ register, errors, control, handleSet, fields, setError }) => {
    return (
        <div className="application-modal_step" key={0}>
            <FormControl className="application-modal_question">
                <ListItem disablePadding>
                    <ListItemButton
                        component="a"
                        href="https://vivabraslav.by/docs/2024/running.pdf"
                        target="_blank"
                        selected={true}>
                        <ListItemIcon>
                            <ContentPasteIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Положение о проведении"
                            secondary="Нажми сюда, чтобы ознакомиться"
                        />
                    </ListItemButton>
                </ListItem>
            </FormControl>

            <Typography variant="inherit" marginBottom="40px">
                После заполнения заявки на почту, которую Вы укажете, придёт информация касаемая оплаты стартового взноса.
                <br />
                <br />
                Заявка будет считаться принятой только после получения нами оплаты.
            </Typography>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Дистанция забега:
                </span>
                <Controller
                    name="type"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup {...field}>
                            <FormControlLabel
                                value="sport-1"
                                control={<Radio />}
                                label="Забег ~1км (семейный)"
                            />
                            <FormControlLabel
                                value="sport-2"
                                control={<Radio />}
                                label="Забег ~2км"
                            />
                            <FormControlLabel
                                value="sport-3"
                                control={<Radio />}
                                label="Забег ~4км"
                            />
                        </RadioGroup>
                    )}
                />
                {errors.type && (
                    <span className="application-modal_question_error">
                        Обязательное поле
                    </span>
                )}
            </FormControl>
        </div>
    );
};

export default StepOne;
