import PageTitle from "../../Components/pageTitle";
import "./style.scss";
import image1 from "../../img/contacts/image1.jpg";
import image2 from "../../img/contacts/image2.jpg";
import partnerIcon from "../../img/contacts/icons/partners.png";
import torgIcon from "../../img/contacts/icons/torg.png";
import prIcon from "../../img/contacts/icons/pr.png";
import sportIcon from "../../img/contacts/icons/sport.png";
import ticketsIcon from "../../img/contacts/icons/tickets.png";
import infoIcon from "../../img/contacts/icons/info.png";

import ContactItem from "./ContactItem";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const Contacts = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }, [pathname]);
    
    return (
        <>
            <div className="contacts">
                <div className="contacts_container">
                    <div className="contacts_wrapper">
                        <div className="contacts_main">
                            <div className="contacts_left">
                                <h1 className="contacts_left_title">
                                    Мы всегда на связи!
                                </h1>
                                <h3 className="contacts_left_subtitle">
                                    Хочешь стать частью нашей истории?
                                    <br />
                                    <br />
                                    Найди нужный контакт и напиши нам! Вместе
                                    создадим нечто особенное!
                                </h3>
                            </div>
                            <div className="contacts_right">
                                <ContactItem
                                    title="Партнёрство"
                                    subtitle="Есть идеи по интеграции бренда в фестиваль, хочешь стать партнёром или спонсором Viva Braslav? Напиши нам, вместе сделаем фестиваль ярче!"
                                    icon={partnerIcon}
                                    iconColor="#C9FFDA"
                                    data={[ {type: "email", linkText: "kya@levol.by"} ]}
                                />
                                <ContactItem
                                    title="Торговля, развлечения"
                                    subtitle="Есть интересные идеи в сфере торговли или развлечений на фестивале? Мы открыты для предложений!"
                                    icon={torgIcon}
                                    iconColor="#EFFFC9"
                                    data={[ {type: "email", linkText: "torg@vivabraslav.by"} ]}
                                />
                                <ContactItem
                                    title="PR"
                                    subtitle="Готовы обсудить PR-кампании и медиапартнёрства? Мы рады новым сотрудничествам!"
                                    icon={prIcon}
                                    iconColor="#C9D1FF"
                                    data={[ {type: "email", linkText: "pr@vivabraslav.by"} ]}
                                />
                                <ContactItem
                                    title="Спорт"
                                    subtitle="Развиваем спортивные проекты и инициативы вместе! Напиши, если есть идеи для коллаборации."
                                    icon={sportIcon}
                                    iconColor="#FFC9FB"
                                    data={[ {type: "email", linkText: "sport@vivabraslav.by"} ]}
                                />
                                <ContactItem
                                    title="Билеты"
                                    subtitle="Есть вопросы о билетах или сотрудничестве в этой сфере? Пиши, не стесняйся!"
                                    icon={ticketsIcon}
                                    iconColor="#C9FCFF"
                                    data={[ {type: "email", linkText: "support@ticketok.by"} ]}
                                />
                                <ContactItem
                                    title="Инфолиния фестиваля"
                                    subtitle="Звони, если есть вопросы по фестивалю! Мы работаем с понедельника по пятницу с 9:00 до 18:00."
                                    icon={infoIcon}
                                    iconColor="#FFC9C9"
                                    data={[ {type: "email", linkText: "info@vivabraslav.by"}, {type: "phone", linkText: "+375(29)277-10-59"} ]}
                                />
                            </div>
                        </div>

                        <div className="contacts_images">
                            <div className="contacts_images_item">
                                <img
                                    src={image1}
                                    alt="photo"
                                    className="contacts_images_item_img"
                                />
                                <span className="contacts_images_item_text">
                                    🔥🫣️ #2024
                                </span>
                            </div>

                            <div className="contacts_images_item">
                                <img
                                    src={image2}
                                    alt="photo"
                                    className="contacts_images_item_img"
                                />
                                <span className="contacts_images_item_text">
                                    🔥🫣️ #2023
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contacts;
