import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    Checkbox,
    ListItemText,
    MenuItem,
    TextField,
    FormControlLabel,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import axios from "axios";
import { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import PageTitle from "../../Components/pageTitle";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import ru from "react-phone-input-2/lang/ru.json";
import "../returns/style.scss";

type Inputs = {
    wrongEmail: string;
    correctEmail: string;
    ticketCategories: string[];
    comment: string;
    agree: boolean;
    problemCategory: string;
    event: "vb";
    appliactionType: "problem";
    phone: string;
};

const TicketProblems = () => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<Inputs>({
        defaultValues: {
            ticketCategories: [],
            wrongEmail: "",
            correctEmail: "",
            comment: "",
            agree: false,
            problemCategory: "",
            event: "vb",
            appliactionType: "problem",
            phone: "",
        },
    });
    const [stateForm, setStateForm] = useState({
        loader: false,
        isFinal: false,
        message: "",
    });
    const [fileCount, setFileCount] = useState(0);

    const options = [
        { label: "Стандарт 2 дня", value: "Стандарт 2 дня" },
        { label: "VIP 2 дня", value: "VIP 2 дня" },
    ];

    const options2 = [
        { label: "not-found", value: "Не могу найти электронный билет" },
        { label: "wrong-email", value: "Ошибка в почте" },
        { label: "payment-problem", value: "Проблема с оплатой" },
        { label: "full-storage", value: "Ящик переполнен" },
        { label: "other", value: "Другое" },
    ];

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const dataSend = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            dataSend.append("file_" + i, selectedFiles[i]);
        }
        dataSend.append("data", JSON.stringify(data));
        dataSend.append("g-recaptcha-response", "test");

        if (!Array.isArray(data.ticketCategories)) {
            data.ticketCategories = [data.ticketCategories];
        }

        setStateForm({
            ...stateForm,
            loader: true,
        });

        const config = {
            method: "post",
            url: "https://vivabraslav.by/prod-api/applications/index.php",
            data: dataSend,
        };

        axios(config)
            .then(function (response) {
                setStateForm({
                    loader: false,
                    isFinal: true,
                    message: response.data.message_user,
                });
                reset();
                setFileCount(0);
                setSelectedFiles([]);
            })
            .catch(function (error) {
                setStateForm({
                    loader: false,
                    isFinal: true,
                    message: error.message_user,
                });
            });
    };

    const handleCloseModalFinal = () => {
        setStateForm({
            ...stateForm,
            isFinal: false,
        });
    };

    const VisuallyHiddenInput = styled("input")({
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: 1,
        overflow: "hidden",
        position: "absolute",
        bottom: 0,
        left: 0,
        whiteSpace: "nowrap",
        width: 1,
    });

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            setFileCount(event.target.files.length);
            setSelectedFiles(Array.from(files));
        }
    };

    return (
        <>
            <PageTitle title="Мне не пришли билеты" subtitle="" />
            <div className="returns">
                <div className="returns_container">
                    <div className="returns_wrapper">
                        <h1 className="returns_title">Информация</h1>
                        <span className="returns_info">
                            С момента покупки до момента получения входящего
                            письма с билетом на Вашу электронную почту может
                            пройти некоторое время (до 30 минут). Проверьте
                            папку “Спам” или “Нежелательные”. Если письмо с
                            билетом всё же не пришло – заполните контактную
                            форму ниже. Частой проблемой может стать
                            переполненный электронный почтовый ящик. Проверьте
                            его на наличие свободного места.
                            <br />
                            <br />
                            Если письмо с билетом всё же не пришло – заполните
                            контактную форму ниже.
                        </span>
                        <h1 className="returns_title">
                            Я хочу восстановить билет
                        </h1>
                        <span
                            className="returns_info"
                            style={{ marginBottom: "10px" }}>
                            Выберите категорию билета
                        </span>
                        <form
                            className="returns_form"
                            onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                {...register("event")}
                                name="event"
                                inputProps={{ type: "hidden" }}
                                defaultValue="vb"
                                style={{ display: "none" }}
                            />
                            <TextField
                                {...register("appliactionType")}
                                name="appliactionType"
                                inputProps={{ type: "hidden" }}
                                defaultValue="problem"
                                style={{ display: "none" }}
                            />
                            <FormControl
                                fullWidth
                                style={{ marginBottom: "10px" }}>
                                <Controller
                                    name="ticketCategories"
                                    control={control}
                                    rules={{
                                        required:
                                            "At least one category is required",
                                    }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            multiple
                                            displayEmpty
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return "Выберите категорию";
                                                }
                                                return selected.join(", ");
                                            }}
                                            size="small"
                                            error={!!errors.ticketCategories}
                                            style={{
                                                backgroundColor: "#fff",
                                                borderRadius: "10px",
                                            }}>
                                            {options.map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}>
                                                    <Checkbox
                                                        size="small"
                                                        checked={
                                                            field.value.indexOf(
                                                                option.value
                                                            ) > -1
                                                        }
                                                    />
                                                    <ListItemText
                                                        primary={option.label}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {errors.ticketCategories && (
                                    <span
                                        className="application-modal_question_error"
                                        style={{ color: "#fff" }}>
                                        Выберите хотя бы одну категорию
                                    </span>
                                )}
                            </FormControl>
                            <span
                                className="returns_info"
                                style={{ marginBottom: "10px" }}>
                                Выберите тему обращения
                            </span>
                            <FormControl
                                fullWidth
                                style={{ marginBottom: "10px" }}>
                                <Controller
                                    name="problemCategory"
                                    control={control}
                                    rules={{
                                        required:
                                            "At least one category is required",
                                    }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            displayEmpty
                                            renderValue={(selected) => {
                                                if (!selected) {
                                                    return "Выберите тему ";
                                                }
                                                return selected;
                                            }}
                                            size="small"
                                            error={!!errors.ticketCategories}
                                            style={{
                                                backgroundColor: "#fff",
                                                borderRadius: "10px",
                                            }}>
                                            {options2.map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}>
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {errors.ticketCategories && (
                                    <span
                                        className="application-modal_question_error"
                                        style={{ color: "#fff" }}>
                                        Это поле обязательно для заполнения
                                    </span>
                                )}
                            </FormControl>
                            <TextField
                                size="small"
                                autoComplete="off"
                                placeholder="Адрес Email (с ошибкой, если была допущена)"
                                fullWidth
                                {...register("wrongEmail", {
                                    required: false,
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message:
                                            "Введите корректный email адрес",
                                    },
                                })}
                                error={!!errors.wrongEmail}
                                helperText={
                                    errors.wrongEmail
                                        ? errors.wrongEmail.message
                                        : ""
                                }
                                style={{ marginBottom: "10px" }}
                                sx={{
                                    "& .MuiFormHelperText-root": {
                                        color: "white !important",
                                    },
                                }}
                                InputProps={{
                                    style: {
                                        background: "#fff",
                                        borderRadius: "10px",
                                    },
                                }}
                                type="email"
                            />
                            <TextField
                                size="small"
                                autoComplete="off"
                                placeholder="Верный адрес Email"
                                fullWidth
                                {...register("correctEmail", {
                                    required:
                                        "Это поле обязательно для заполнения",
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message:
                                            "Введите корректный email адрес",
                                    },
                                })}
                                error={!!errors.correctEmail}
                                helperText={
                                    errors.correctEmail
                                        ? errors.correctEmail.message
                                        : ""
                                }
                                style={{ marginBottom: "10px" }}
                                sx={{
                                    "& .MuiFormHelperText-root": {
                                        color: "white !important",
                                    },
                                }}
                                InputProps={{
                                    style: {
                                        background: "#fff",
                                        borderRadius: "10px",
                                    },
                                }}
                                type="email"
                            />
                            <FormControl
                                fullWidth
                                style={{ marginBottom: "10px" }}>
                                <Controller
                                    name="phone"
                                    control={control}
                                    render={({ field }) => (
                                        <ReactPhoneInput
                                            {...register("phone", {
                                                required: true,
                                            })}
                                            {...field}
                                            value={field.value} // Set the default country value
                                            country="by"
                                            // onlyCountries={['by', 'ru', 'lt', 'lv', 'pl', 'ua']}
                                            localization={ru}
                                            inputProps={{
                                                name: "phone",

                                                placeholder:
                                                    "Начните писать тут...",
                                                required: true,
                                                autoformat: true,
                                            }}
                                            onChange={(value) =>
                                                field.onChange(value)
                                            } // Update the field value on change
                                        />
                                    )}
                                />
                                {errors.phone && (
                                    <span
                                        className="application-modal_question_error"
                                        style={{ color: "#fff" }}>
                                        Это поле обязательно для заполнения
                                    </span>
                                )}
                            </FormControl>
                            <TextField
                                size="small"
                                placeholder="Постарайтесь описать проблему как можно подробнее"
                                multiline
                                rows={4}
                                fullWidth
                                {...register("comment", {
                                    required:
                                        "Это поле обязательно для заполнения",
                                })}
                                error={!!errors.comment}
                                helperText={
                                    errors.comment ? errors.comment.message : ""
                                }
                                style={{ marginBottom: "10px" }}
                                sx={{
                                    "& .MuiFormHelperText-root": {
                                        color: "white !important",
                                    },
                                }}
                                InputProps={{
                                    style: {
                                        background: "#fff",
                                        borderRadius: "10px",
                                    },
                                }}
                            />
                            <span
                                className="returns_info"
                                style={{ margin: "20px 0 10px 0" }}>
                                Приложите копию чека об оплате или выписку из
                                банка
                            </span>
                            <Button
                                style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    background: "#fff",
                                    color: "#000",
                                    marginBottom: "15px",
                                }}
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}>
                                {fileCount > 0
                                    ? `Загружено ${fileCount} файлов`
                                    : "Загрузить файлы"}
                                <VisuallyHiddenInput
                                    type="file"
                                    // {...register("attachments")}
                                    multiple
                                    accept="application/pdf,image/*"
                                    onChange={handleFileSelect}
                                />
                            </Button>

                            <FormControl className="application-modal_question">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            {...register("agree", {
                                                required: true,
                                            })}
                                            sx={{
                                                color: "#000",
                                                "&.Mui-checked": {
                                                    color: "#000",
                                                },
                                            }}
                                        />
                                    }
                                    label="Я подтверждаю, что ознакомлен(-а) с политикой обработки персональных данных и даю согласие на обработку персональных данных."
                                    sx={{
                                        "& .MuiFormControlLabel-label": {
                                            fontSize: "12px",
                                            color: "#000",
                                        },
                                    }}
                                />
                                {errors.agree && (
                                    <span
                                        className="application-modal_question_error"
                                        style={{ color: "#000" }}>
                                        Это поле обязательно для заполнения
                                    </span>
                                )}
                            </FormControl>
                            <button type="submit" className="returns_form_send">
                                <div
                                    className="loader"
                                    style={
                                        stateForm.loader
                                            ? { display: "block" }
                                            : { display: "none" }
                                    }></div>
                                <span
                                    style={
                                        !stateForm.loader
                                            ? { display: "block" }
                                            : { display: "none" }
                                    }>
                                    Отправить
                                </span>
                            </button>
                        </form>
                        {/* Backdrop for Loader */}
                        <Backdrop
                            sx={{
                                color: "#fff",
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                            open={stateForm.loader}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        {/* Final Message Dialog */}
                        <Dialog
                            open={stateForm.isFinal}
                            onClose={handleCloseModalFinal}>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {stateForm.message}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleCloseModalFinal}
                                    autoFocus>
                                    Закрыть
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TicketProblems;
