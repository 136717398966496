import { FormControl, TextField } from "@mui/material";

const StepThree = ({register, errors, selectedFiles, handleFileSelect}) => {
    
    return(
        <div className="application-modal_step" key={2}>
            
            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Размеры объекта:</span>
                <TextField 
                    size="small" 
                    autoComplete="off"
                    {...register('companySize', { required: true })}
                    error={!!errors.companySize}
                    helperText={errors.companySize && "Обязательное поле"}
                    label="ДхШ в метрах"
                    style={{marginTop: "10px"}}
                />
                
            </FormControl>

            {/* <FormControl className="application-modal_question">
                <span className="application-modal_question_title">Банковские реквизиты:</span>
                <TextField 
                    size="small" 
                    autoComplete="off" 
                    placeholder="УНП, юр. адрес, банковские реквизиты" 
                    {...register('bankRequisites', { required: true })}
                    error={!!errors.bankRequisites}
                    helperText={errors.bankRequisites && "Обязательное поле"}
                />
            </FormControl>  */}

            <div className="application-modal_question">
                <span className="application-modal_question_title">Фотографии объекта:</span>
                <div className="input__wrapper">
                    <input name="file" type="file" id="input__file" className="input input__file"
                    accept="image/png, image/jpg, image/jpeg" multiple onChange={handleFileSelect} />
                    <label htmlFor="input__file" className="input__file-button">
                    <span className="input__file-button-text">{selectedFiles.length > 0 ? "Прикреплено файлов: "+selectedFiles?.length : "Нажмите сюда для загрузки файла(ов)"}</span>
                    </label>
                </div>
            </div>
                            
        </div>
    );
}

export default StepThree;