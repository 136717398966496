import React, { useState } from "react";
import axios from "axios";
import { useForm, SubmitHandler } from "react-hook-form";

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFive from "./StepFive";

import ProgresBar from "../FormComponents/ProgresBar";
import NavigationButtons from "../FormComponents/NavigationButtons";
import StepLoading from "../FormComponents/StepLoading";
import StepSuccess from "../FormComponents/StepSuccess";
import StepWrong from "../FormComponents/StepWrong";

import '../style_form.scss';
import FormHeader from "../FormComponents/FormHeader";

interface Inputs {
    appliactionType: string,
    fullNameCompany: string,
    torgNameCompany: string,
    voltage220: boolean,
    voltage380: boolean,
    powerFor220: number | string,
    powerFor380: number | string,
    bankTerminal: string,
    companySize: string,
    personalCount: number,
    // bankRequisites: string,
    contactPerson: string,
    contactPhone: string,
    contactEmail: string,
    contactLink: string,
    contactComment: string
}

const defaultValues: Inputs = {
    appliactionType: 'entertainment',
    fullNameCompany: '',
    torgNameCompany: '',
    voltage220: false,
    voltage380: false,
    powerFor220: '',
    powerFor380: '',
    bankTerminal: '',
    companySize: '',
    personalCount: 1,
    // bankRequisites: '',
    contactPerson: '',
    contactPhone: '',
    contactEmail: '',
    contactLink: '',
    contactComment: ''
}; 

const EntertainmentsForm = () => {

    const applicationName="Заявка на участие в фестивале (Развлечения)";

    const { control, register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>({defaultValues});
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const dataSend = new FormData();
        const config = {
            method: 'post',
            url: 'https://vivabraslav.by/prod-api/applications.php',
            data : dataSend
        };

        const sendToServer = () => {
            for (let i = 0; i < selectedFiles.length; i++) {
                dataSend.append('file_' + i, selectedFiles[i]);
            }
            dataSend.append('data', JSON.stringify(data));
            dataSend.append('g-recaptcha-response', "test");

            axios(config)
            .then(function () {
                setStep({ ...step, actual: 6 });
            })
            .catch(function () {
                setStep({ ...step, actual: 7 });
            });
        }

        if(step.actual === step.max || step.actual === 7 || step.actual === 6) {
            setStep({ ...step, actual: 5 });
            sendToServer();
        } else {
            setStep({ ...step, actual: step.actual + 1 });
        }

    }

    const [step, setStep] = useState({
        actual: 0,
        max: 3
    });

    const changeStep = () => {
        if(step.actual > 0) {
            setStep({
                ...step,
                actual: step.actual - 1
            });
        }
    }

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
          setSelectedFiles(Array.from(files));
        }
    };

    const getStep = () => {
        switch (step.actual) {
            case 0:
                return(
                    <StepOne 
                        register={register} 
                        errors={errors}
                    />
                );
            case 1:
                return(
                    <StepTwo 
                        register={register} 
                        errors={errors}
                        watch={watch}
                        control={control}
                    />
                );    
            case 2:
                return(
                    <StepThree 
                        register={register} 
                        errors={errors}
                        selectedFiles={selectedFiles}
                        handleFileSelect={handleFileSelect}
                    />
                );    
            case 3:
                return(
                    <StepFive 
                        register={register} 
                        errors={errors}
                        control={control}
                    />
                );    
            case 5: 
                return(<StepLoading key={6}/>);
            case 6: 
                return(<StepSuccess key={7}/>);    
            case 7: 
                return(<StepWrong key={8}/>);     
        }
    }

    return(
        <div className="application-modal_wrapper">
            <FormHeader applicationName={applicationName} />
            <form className="application-modal_steps" onSubmit={handleSubmit(onSubmit)}>
                {getStep()}
                <div className="application-modal_navigation">
                    <NavigationButtons 
                        stepInfo={step} 
                        stepPrev={changeStep} 
                    />
                    <ProgresBar stepInfo={step}/>
                </div>
            </form>   
        </div>
    );
}

export default EntertainmentsForm;