import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createRef } from "react";

import Returns from "./Pages/returns";
import Contract from "./Pages/contract";
import Payment from "./Pages/payment";
import Index from "./Pages/index/index";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Applications from "./Pages/applications";
import Ticketok from "./Pages/applicationsTicketok";
import Miss from "./Pages/miss";
import Contacts from "./Pages/contacts";
import Tickets from "./Pages/tickets";
import Alfabank from "./Pages/alfabank";
import HowToGet from "./Pages/howToGet";
import Places from "./Pages/places";
import CampingRules from "./Pages/campingRules";
import Program from "./Pages/program";
import Forbidden from "./Pages/forbidden";
import { useState, useEffect } from "react";
import GlampingRules from "./Pages/glampingRules";
import FestivalRules from "./Pages/festivalRules";
import About from "./Pages/about";
import Privacy from './Pages/privacy';
import Lineup from "./Pages/lineup";
import Maps from "./Pages/maps";
import TicketsTest from "./Pages/tickets_bepaid";
import Contracts from "./Pages/contracts";
import TicketProblems from "./Pages/ticketProblems";
import CompanyTickets from "./Pages/companyTickets";
import SocialTickets from "./Pages/socialTickets";
import Background from "./Components/Background";

function App() {
    const [statusCode, setStatusCode] = useState<number>();

    useEffect(() => {
        fetch(window.location.href, { method: "HEAD" }).then((response) => {
            setStatusCode(response.status);
        });
    }, []);

    if (statusCode === 403) {
        //return <Navigate to="/403" />;
        return (
            <>
                <Router>
                    <Routes>
                        <Route
                            path="/403"
                            caseSensitive
                            element={<Forbidden />}
                        />
                    </Routes>
                </Router>
            </>
        );
    }

    const routes = [
        { path: "/", element: <Index />, nodeRef: createRef() },
        { path: "/about", element: <About />, nodeRef: createRef() },
        { path: "/payment", element: <Payment />, nodeRef: createRef() },
        { path: "/how-to-order", element: <Payment />, nodeRef: createRef() },
        { path: "/returns", element: <Returns />, nodeRef: createRef() },
        { path: "/problems", element: <TicketProblems />, nodeRef: createRef() },
        // { path: "/maps", element: <Maps />, nodeRef: createRef() },
        { path: "/tickets", element: <Tickets />, nodeRef: createRef() },
        { path: "/tickets_dev_qa", element: <TicketsTest />, nodeRef: createRef() },
        {
            path: "/applications",
            element: <Applications />,
            nodeRef: createRef(),
        },
        // {
        //     path: "/ticketok",
        //     element: <Ticketok />,
        //     nodeRef: createRef(),
        // },
        { path: "/miss", element: <Miss />, nodeRef: createRef() },
        { path: "/contracts", element: <Contracts />, nodeRef: createRef() },
        { path: "/contacts", element: <Contacts />, nodeRef: createRef() },
        { path: "/alfabank", element: <Alfabank />, nodeRef: createRef() },
        { path: "/route", element: <HowToGet />, nodeRef: createRef() },
        { path: "/places", element: <Places />, nodeRef: createRef() },
        { path: "/program", element: <Program />, nodeRef: createRef() },
        { path: "/tickets-company", element: <CompanyTickets />, nodeRef: createRef() },
        { path: "/tickets-social", element: <SocialTickets />, nodeRef: createRef() },
        // { path: "/lineup", element: <Lineup />, nodeRef: createRef() },
        { path: "*", element: <Index />, nodeRef: createRef() },
    ];

    return (
      <>
      <Router>
        <Header />
        <Routes>
          {routes.map((item) => (
            <Route key={item.path} path={item.path} element={item.element} />
          ))}
        </Routes>
        <Footer />
        <Background />
      </Router>
    </>
    );
}

export default App;
