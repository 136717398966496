import { useState } from "react";
import PageTitle from "../../Components/pageTitle";

import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';

// import data from '../../applications.json';
import ApplicationItem from "./item";

import './style.scss';
import FormModal from "./FormModal";
import { Skeleton } from "@mui/material";
import { indigo } from "@mui/material/colors";
import { error } from "console";
import tickets from "../tickets";
import Ticket from "../tickets/ticket";
import { useGetApplicationsQuery } from "../../redux/api";

const Applications = () => {


    // eslint-disable-next-line
    // const [applications, setApplications] = useState(data.applications);
    const { isLoading, error } = useGetApplicationsQuery();
    const { isOpenModal, applications } = useAppSelector((state: RootState) => state.applicationsStore);

    const applicationsItems = () => {
        if(!isLoading && !error) {
            // eslint-disable-next-line
            return applications?.map((item: any, categoryIndex) => {
                return(<ApplicationItem key={item.id} itemInfo={item} />);
            });
        } else {
            return (
                <div className="tickets_loader">
                    <Skeleton 
                        variant="rounded" 
                        width="100%" 
                        height="178px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "16px", marginBottom: "20px"}}
                    />
                    <Skeleton 
                        variant="rounded" 
                        width="100%" 
                        height="178px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "16px", marginBottom: "40px"}}
                    />
                    <Skeleton 
                        variant="rounded" 
                        width="100%" 
                        height="178px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "16px", marginBottom: "20px"}}
                    />
                    <Skeleton 
                        variant="rounded" 
                        width="100%" 
                        height="178px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "16px", marginBottom: "20px"}}
                    />
                    <Skeleton 
                        variant="rounded" 
                        width="100%" 
                        height="178px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "16px", marginBottom: "20px"}}
                    />
                    <Skeleton 
                        variant="rounded" 
                        width="100%" 
                        height="178px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "16px", marginBottom: "20px"}}
                    />
                </div>
            );
        }
    }
    
    return(
        <>
        <PageTitle 
            title="Viva Braslav 2025"
            subtitle="Заявки на участие в фестивале"
        />
        <div className={isOpenModal ? 'applications open-form' : 'applications'}>
            <div className="applications_container">
                <div className="applications_wrapper">
                    {applicationsItems()}
                    {/* {applications.map((item) => {
                        return(<ApplicationItem key={item.id} itemInfo={item} />);
                    })} */}
                </div>
            </div>
            <FormModal />
        </div>
        </>
    );
}

export default Applications;