import Ticket from "./ticket";
import './../tickets/style.scss';
import ModalPayment from "./ModalPayment";
import ModalMore from "./ModalMore";
import PageTitle from "../../Components/pageTitle";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useGetTicketsQuery, useGetTicketsTestQuery } from "../../redux/api";
import { Skeleton } from "@mui/material";
import { indigo } from '@mui/material/colors';
//import axios from "axios";

const TicketsTest = () => {

    const { isLoading, error } = useGetTicketsTestQuery();
    const { tickets, isOpenModalBuy, isOpenModalMore } = useAppSelector((state: RootState) => state.ticketsModalStore);

    const products = () => {
        if(!isLoading && !error) {
            // eslint-disable-next-line
            return tickets?.map((category: any, categoryIndex) => {
                return (
                <div className="tickets_category" key={categoryIndex}>
                    <h2 className="tickets_category_title" key={categoryIndex}>{category.title}</h2>
                    {category.items.map((item, itemIndex) => {
                        return (
                            <Ticket 
                                key = {itemIndex}
                                categoryId = {category.id}
                                info = {item}
                            />
                        );
                    })}
                </div>
                );
            });
        } else {
            return (
                <div className="tickets_loader">
                    <Skeleton 
                        variant="rounded" 
                        width="232px" 
                        height="30px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "10px", marginBottom: "22px"}}
                    />
                    <Skeleton 
                        variant="rounded" 
                        width="100%" 
                        height="178px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "16px", marginBottom: "20px"}}
                    />
                    <Skeleton 
                        variant="rounded" 
                        width="100%" 
                        height="178px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "16px", marginBottom: "40px"}}
                    />
                    <Skeleton 
                        variant="rounded" 
                        width="232px" 
                        height="30px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "10px", marginBottom: "22px"}}
                    />
                    <Skeleton 
                        variant="rounded" 
                        width="100%" 
                        height="178px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "16px", marginBottom: "20px"}}
                    />
                </div>
            );
        }
    }
        
    return (
        <>
            <PageTitle 
                title="Viva Braslav 2025"
                subtitle="Билеты всех категорий"
            />
            <div className={isOpenModalBuy ? 'tickets open-payment' : 'tickets' && isOpenModalMore ? 'tickets open-more' : 'tickets'}>
                <div className='tickets_container'>
                    <div className="tickets_wrapper">
                        {products()}
                    </div>
                </div>
                <ModalMore />
                <ModalPayment />
            </div>
        </>
    );
}

export default TicketsTest;