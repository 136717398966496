import React, { useState, useRef, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import icon from '../../../img/alfabank/acc-spoiler.svg';
import "./style.scss";

const FAQItem = ({title, text}) => {
    const [open, setOpen] = useState(false);
    const contentRef = useRef(null);
    const [height, setHeight] = useState("0px");

    const toggleAccordion = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if (contentRef.current) {
            setHeight(open ? `${contentRef.current.scrollHeight}px` : "0px");
        }
    }, [open]);

    useEffect(() => {
            AOS.init();
          }, [])

    return (
        <div className="alfabank-faq" data-aos="fade-right">
            <div className="alfabank-faq_item">
                <div
                    className="alfabank-faq_item_header"
                    onClick={toggleAccordion}>
                    <h4 className="alfabank-faq_item_header_text">
                        {title}
                    </h4>
                    <div
                        className="alfabank-faq_item_header_icon"
                        style={{
                            transform: open ? "rotate(180deg)" : "rotate(-45deg)",
                        }}>
                        <img src={icon} alt="" className="alfabank-faq_item_header_icon_img" />
                    </div>
                </div>
                <div
                    ref={contentRef}
                    className="alfabank-faq_item_header_body"
                    style={{
                        maxHeight: height,
                        overflow: "hidden",
                        transition: "max-height 0.4s ease",
                    }}>
                    <div className="alfabank-faq_item_header_body_text">
                        <p>
                            {text}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQItem;
