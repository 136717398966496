import React from "react";
import FAQItem from "../FAQItem";
import './style.scss';

const FaqBlock = () => {
    
    return (
        <div className="faq-block">
            <div className="faq-block_container">
                <div className="faq-block_wrapper">
                    {/* <span className="faq-block_title">Альфа Бонус до 50 BYN</span> */}
                    <FAQItem
                        title="Что такое Альфа Бонус?"
                        text="Альфа Бонус — программа лояльности Альфа Банка с начислением бонусов за покупки. Бонусы можно менять на деньги, направлять на благотворительность или оплачивать ими услуги в ЕРИП. 1 бонус = 1 BYN."
                    />
                    <FAQItem
                        title="Я получу Альфа Бонусы за покупку билетов?"
                        text="Да! Оплатите билеты картой VISA «Пакета решений»* на официальном сайте фестиваля и верните бонусами 10% от суммы купленных билетов. Так можно получить до 50 Альфа Бонусов!"
                    />
                    <FAQItem
                        title="Как получить карту VISA в Пакете решений?"
                        text="Оформите её онлайн в INSNC, а если вы новый клиент - получите в подарок 10 Альфа Бонусов! Для этого, после подключения Пакета решений, введите в течение 3-х дней промокод «VIVA25» в мобильном банке INSNC в разделе Альфа Бонус в блоке “Накопить”-”Выгодно дружить” и оплатите билет на Viva Braslav. "
                    />
                </div>
            </div>
        </div>
    );
};

export default FaqBlock;
