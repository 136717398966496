import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useEffect, useState } from "react";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { useLocation } from "react-router-dom";
import axios from "axios";
import AOS from "aos";

import "swiper/css";
import "swiper/css/pagination";
import "./style.scss";
import "aos/dist/aos.css";

import gallery1 from "../../img/miss/gallery/new/1.jpg";
import gallery2 from "../../img/miss/gallery/new/2.jpg";
import gallery3 from "../../img/miss/gallery/new/3.jpg";
import gallery4 from "../../img/miss/gallery/new/4.jpg";
import gallery5 from "../../img/miss/gallery/new/5.jpg";

import partner1 from "../../img/miss/partners/1.png";
import partner2 from "../../img/miss/partners/2.png";
import partner3 from "../../img/miss/partners/3.png";
import partner4 from "../../img/miss/partners/4.png";
import partner5 from "../../img/miss/partners/5.png";
import partner6 from "../../img/miss/partners/6.png";
import partner7 from "../../img/miss/partners/7.png";
import partner8 from "../../img/miss/partners/8.png";
import partner9 from "../../img/miss/partners/9.png";
import partner10 from "../../img/miss/partners/10.png";

import back from "../../img/miss/back.png";
import frudozaLogo from "../../img/miss/partner.png";

import back1 from "../../img/miss/background.png";

import { CircularProgress } from "@mui/material";

import girl1 from "../../img/miss/24/left_girl.png";
import girl2 from "../../img/miss/24/main_girl.png";
import girl3 from "../../img/miss/24/right_girl.png";

const Miss = () => {
    const [items, setItems] = useState([]);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, [pathname]);

    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(() => {
        const config = {
            method: "get",
            url: "https://vivabraslav.by/prod-api/insta.php",
        };

        axios(config)
            .then(function (response) {
                setItems(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const [isLoaded, setIsLoaded] = useState({
        girl: false,
        back1: false,
        back2: false,
        all: false,
    });

    function handleLoad(name: string) {
        setIsLoaded({
            ...isLoaded,
            [name]: true,
        });
    }

    function handleError() {
        console.log("err");
    }

    console.log(isLoaded);

    if (!isLoaded.back1) {
        return (
            <>
                <img
                    src={back1}
                    alt="preload item"
                    onLoad={() => handleLoad("back1")}
                    onError={handleError}
                    style={{ display: isLoaded.back1 ? "none" : "none" }}
                />

                <div className="loader-page">
                    <CircularProgress />
                </div>
            </>
        );
    }

    return (
        <>
            <div className="miss">
                <div className="miss_container">
                    <div className="miss_wrapper">
                        <div className="miss_girls">
                            <span
                                className="miss_girls_miss"
                                data-aos="fade-down">
                                Miss
                            </span>
                            <span
                                className="miss_girls_vb"
                                data-aos="fade-down"
                                data-aos-delay="100">
                                viva braslav
                            </span>
                            <div className="miss_girls_wrapper">
                                <div className="miss_girls_1">
                                    <img
                                        src={girl1}
                                        alt="Miss VB"
                                        data-aos="fade-up-right"
                                        data-aos-delay="800"
                                    />
                                </div>
                                <div className="miss_girls_2">
                                    <img
                                        src={girl2}
                                        alt="Miss VB"
                                        data-aos="fade-up"
                                        data-aos-delay="1200"
                                    />
                                </div>
                                <div className="miss_girls_3">
                                    <img
                                        src={girl3}
                                        alt="Miss VB"
                                        data-aos="fade-up-left"
                                        data-aos-delay="800"
                                    />
                                </div>
                                <div className="miss_girls_bg"></div>
                            </div>
                            {/* <div className="miss_girls_wrapper-year">
                                <span
                                    className="miss_girls_20"
                                    data-aos="fade-down"
                                    data-aos-delay="200">
                                    20
                                </span>
                                <span
                                    className="miss_girls_24"
                                    data-aos="fade-down"
                                    data-aos-delay="400">
                                    24
                                </span>
                            </div> */}
                            {/* <a href="https://docs.google.com/forms/u/0/d/1iN1tdQAfcMTPo2m3OY_Y0P37-DwPmVrW8zYammrAFA0/viewform" target="_blank" className="miss_girls_apply" data-aos="zoom-in" data-aos-delay="1500">
                                <span className="miss_girls_apply_text">
                                    Отправить заявку
                                </span>
                            </a> */}
                            <div
                                className="miss_girls_apply"
                                data-aos="zoom-in"
                                data-aos-delay="1500">
                                <span className="miss_girls_apply_text">
                                    Скоро
                                </span>
                            </div>
                        </div>
                        <div className="miss_information">
                            <span className="miss_information_text">
                                Конкурс красоты Miss Viva Braslav 2025 
                               
                            </span>
                            <span className="miss_information_primary-text">
                                Скоро здесь появится информация о предстоящем конкурсе
                            </span>
                        </div>
                    </div>
                    {/* <div
                        className="miss-apply-btn-mobile miss-apply">
                        Приём окончен
                    </div> */}
                </div>
            </div>
            {/* <div className="miss-partners">
                <Swiper
                    slidesPerView={6}
                    spaceBetween={50}
                    loop={true}
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    className="miss-partners_swiper"
                    breakpoints={{
                        100: {
                            slidesPerView: 1,
                        },
                        480: {
                            slidesPerView: 2,
                        },
                        780: {
                            slidesPerView: 4,
                        },
                        992: {
                            slidesPerView: 6,
                        },
                    }}>
                    <SwiperSlide className="miss-partners_slide">
                        <img src={partner1} alt="partner 1" />
                    </SwiperSlide>
                    <SwiperSlide className="miss-partners_slide">
                        <img src={partner2} alt="partner 2" />
                    </SwiperSlide>
                    <SwiperSlide className="miss-partners_slide">
                        <img src={partner3} alt="partner 3" />
                    </SwiperSlide>
                    <SwiperSlide className="miss-partners_slide">
                        <img src={partner4} alt="partner 4" />
                    </SwiperSlide>
                    <SwiperSlide className="miss-partners_slide">
                        <img src={partner5} alt="partner 5" />
                    </SwiperSlide>
                    <SwiperSlide className="miss-partners_slide">
                        <img src={partner6} alt="partner 6" />
                    </SwiperSlide>
                    <SwiperSlide className="miss-partners_slide">
                        <img src={partner7} alt="partner 7" />
                    </SwiperSlide>
                    <SwiperSlide className="miss-partners_slide">
                        <img src={partner8} alt="partner 8" />
                    </SwiperSlide>
                    <SwiperSlide className="miss-partners_slide">
                        <img src={partner9} alt="partner 9" />
                    </SwiperSlide>
                    <SwiperSlide className="miss-partners_slide">
                        <img src={partner10} alt="partner 10" />
                    </SwiperSlide>
                </Swiper>
            </div> */}
            <div className="miss-how">
                <div className="miss-how_container">
                    <h1 className="miss-how_title">Как принять участие?</h1>
                    <div className="miss-how_wrapper">
                        <div className="miss-how_item" data-aos="fade-up">
                            <span className="miss-how_item_number">1</span>
                            {/* <span className="miss-how_item_text">
                                Переходи по{" "}
                                <a
                                    href="https://docs.google.com/forms/u/0/d/1iN1tdQAfcMTPo2m3OY_Y0P37-DwPmVrW8zYammrAFA0/viewform"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="miss-how_yellow">
                                    ссылке
                                </a>{" "}
                                и отправляй заявку на участие
                            </span> */}
                            <span className="miss-how_item_text">
                                Отправляй заявку на участие
                            </span>
                        </div>
                        <div
                            className="miss-how_item"
                            data-aos="fade-up"
                            data-aos-delay="300">
                            <span className="miss-how_item_number">2</span>
                            <span className="miss-how_item_text">
                                Жди приглашение на e-mail на кастинг в Минске
                            </span>
                        </div>
                        <div
                            className="miss-how_item"
                            data-aos="fade-up"
                            data-aos-delay="600">
                            <span className="miss-how_item_number">3</span>
                            <span className="miss-how_item_text">
                                Приезжай на кастинг
                                <br />и прояви себя
                            </span>
                        </div>
                    </div>
                    <span className="miss-how_info">
                        По итогам кастинга организаторы конкурса совместно с
                        членами жюри выберут 15&nbsp;претенденток на звание
                        Miss&nbsp;Viva&nbsp;Braslav&nbsp;, которые примут
                        участие в финале
                    </span>
                </div>
            </div>
            <div className="miss-gallery">
                <Swiper
                    slidesPerView={3.5}
                    spaceBetween={50}
                    centeredSlides={true}
                    loop={true}
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    className="miss-gallery_swiper"
                    breakpoints={{
                        100: {
                            slidesPerView: 1,
                        },
                        480: {
                            slidesPerView: 2,
                        },
                        780: {
                            slidesPerView: 3,
                        },
                        992: {
                            slidesPerView: 4,
                        },
                    }}>
                    <SwiperSlide className="miss-gallery_slide">
                        <img src={gallery1} alt="gallery 1" />
                    </SwiperSlide>
                    <SwiperSlide className="miss-gallery_slide">
                        <img src={gallery2} alt="gallery 2" />
                    </SwiperSlide>
                    <SwiperSlide className="miss-gallery_slide">
                        <img src={gallery3} alt="gallery 3" />
                    </SwiperSlide>
                    <SwiperSlide className="miss-gallery_slide">
                        <img src={gallery4} alt="gallery 4" />
                    </SwiperSlide>
                    <SwiperSlide className="miss-gallery_slide">
                        <img src={gallery5} alt="gallery 5" />
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className="miss-why">
                <div className="miss-why_container">
                    <div className="miss-why_wrapper">
                        <span className="miss-why_title">
                            <span className="miss-how_yellow">Почему</span>
                            <br />
                            стоит поучаствовать?
                        </span>
                        <div className="miss-why_arguments">
                            <ul className="miss-why_arguments_part">
                                <li className="miss-why_arguments_part_item">
                                    Работа с классными брендами
                                </li>
                                <li className="miss-why_arguments_part_item">
                                    Фотосессии
                                </li>
                                <li className="miss-why_arguments_part_item">
                                    Мастер-классы
                                </li>
                                <li className="miss-why_arguments_part_item">
                                    Много новых интересных знакомств
                                </li>
                            </ul>
                            <ul className="miss-why_arguments_part">
                                <li className="miss-why_arguments_part_item">
                                    Возможность заявить о себе
                                </li>
                                <li className="miss-why_arguments_part_item">
                                    Приятные подарки от партнеров
                                </li>
                                <li className="miss-why_arguments_part_item">
                                    Незабываемое время на Viva Braslav
                                </li>
                            </ul>
                        </div>
                    </div>
                    <span className="miss-why_info">
                        Победительница станет обладательницей титула{" "}
                        <b>“Miss Viva Braslav</b>, получит главный приз и
                        множество других приятных подарков
                    </span>
                </div>
            </div>
            <ParallaxProvider>
                <div className="miss-bg">
                    <div className="miss-bg_block">
                        <span className="miss-bg_title">
                            Хочешь стать Miss&nbsp;Viva&nbsp;Braslav
                        </span>
                        {/* <span className="miss-bg_year">2024</span> */}
                        {/* <a
                            href="https://docs.google.com/forms/u/0/d/1iN1tdQAfcMTPo2m3OY_Y0P37-DwPmVrW8zYammrAFA0/viewform"
                            target="_blank"
                            rel="noreferrer"
                            className="miss-bg_btn">
                            Отправить заявку
                        </a> */}
                    </div>
                    <Parallax speed={15}>
                        <img src={back} alt="Parallax" />
                    </Parallax>
                </div>
            </ParallaxProvider>
            <div className="miss-info">
                <div className="miss-info_container">
                    <div className="miss-info_wrapper">
                        {/* <span className="miss-info_text">
                            Всю информацию об условиях участия в конкурсе Miss
                            Viva Braslav 2024 и порядке его проведения читайте в{" "}
                            <a
                                href="https://vivabraslav.by/docs/2024/miss2024.pdf"
                                target="_blank"
                                rel="noreferrer"
                                className="miss-how_yellow">
                                Положении конкурса
                            </a>
                        </span> */}
                        <div className="miss-info_instagram">
                            <span className="miss-info_instagram_primary">
                                Следите за подробностями конкурса
                            </span>
                            <span className="miss-info_instagram_secondary">
                                в нашем инстаграм-аккаунте
                            </span>
                            <a
                                href="https://www.instagram.com/missvivabraslav/"
                                target="_blank"
                                rel="noreferrer"
                                className="miss-info_instagram_link">
                                @missvivabraslav
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {items.length > 0 && items[0] != null && (
                <div className="insta">
                    <Swiper
                        modules={[Autoplay]}
                        slidesPerView={4}
                        spaceBetween={20}
                        loop={true}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        className="insta_swiper"
                        breakpoints={{
                            100: {
                                slidesPerView: 1,
                            },
                            480: {
                                slidesPerView: 2,
                            },
                            780: {
                                slidesPerView: 3,
                            },
                            992: {
                                slidesPerView: 4,
                            },
                        }}>
                        <SwiperSlide className="insta_slide ">
                            <div className="insta_end">
                                <a
                                    href="https://www.instagram.com/missvivabraslav/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="insta_end_logo">
                                    <div className="insta_end_logo_img"></div>
                                </a>
                                <a
                                    href="https://www.instagram.com/missvivabraslav/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="insta_end_nickname">
                                    missvivabraslav
                                </a>
                                <a
                                    href="https://www.instagram.com/missvivabraslav/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="insta_end_link">
                                    Подписаться
                                </a>
                            </div>
                        </SwiperSlide>
                        {items?.map((item) => {
                            return (
                                <SwiperSlide className="insta_slide">
                                    <div className="insta_slide_head">
                                        <div className="insta_slide_head_logo"></div>
                                        <div className="insta_slide_head_texts">
                                            <a
                                                href={item.permalink}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="insta_slide_head_nickname">
                                                missvivabraslav
                                            </a>
                                            <h6 className="insta_slide_head_geo">
                                                Minsk, Belarus
                                            </h6>
                                        </div>
                                        <div className="insta_slide_head_btn">
                                            <button className="insta_slide_footer_dots"></button>
                                        </div>
                                    </div>
                                    <a
                                        href={item.permalink}
                                        target="_blank"
                                        rel="noreferrer">
                                        <div
                                            className="insta_slide_photo"
                                            style={{
                                                background: `url(${item.media_url}) rgb(255, 181, 239) center center`,
                                                backgroundSize: "cover",
                                            }}></div>
                                    </a>
                                    <div className="insta_slide_footer">
                                        <div className="insta_slide_footer_left">
                                            <a
                                                href={item.permalink}
                                                target="_blank"
                                                rel="noreferrer">
                                                <div className="insta_slide_footer_like"></div>
                                            </a>
                                            <a
                                                href={item.permalink}
                                                target="_blank"
                                                rel="noreferrer">
                                                <div className="insta_slide_footer_comment"></div>
                                            </a>
                                            <a
                                                href={item.permalink}
                                                target="_blank"
                                                rel="noreferrer">
                                                <div className="insta_slide_footer_direct"></div>
                                            </a>
                                        </div>
                                        <div className="insta_slide_footer_right">
                                            <a
                                                href={item.permalink}
                                                target="_blank"
                                                rel="noreferrer">
                                                <div className="insta_slide_footer_save"></div>
                                            </a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            )}
        </>
    );
};

export default Miss;
