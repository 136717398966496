import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from '../api';

export interface UserState {
  applications: any,
  selectedForm: number;
  isOpenModal: boolean;
}

const initialState: UserState = {
  applications: [],
  selectedForm: null,
  isOpenModal: false
};

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<number>) {
      state.selectedForm = action.payload;
      state.isOpenModal = true;
    },
    closeModal(state) {
      state.isOpenModal = false;
    }
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(
      api.endpoints.getApplications.matchFulfilled,
      (state, { payload }) => {
        state.applications = payload.applications;
      }
    )
  },
});

export const {
  openModal,
  closeModal
} = applicationsSlice.actions;

export default applicationsSlice.reducer;