import './style.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const InstructionBlock = () => {
    useEffect(() => {
        AOS.init();
      }, [])
    return(
        <div className="instruction" data-aos="zoom-in" id='how-to-get'> 
            <div className="instruction__container">
                <h1 className="instruction__title">
                    Как использовать преимущества карт Visa Альфа Банка на фуд-корте фестиваля?
                </h1>
                <div className="instruction__wrapper">
                    <div className="instruction__item" data-aos="zoom-in">
                        <div className="instruction__item__count">
                            <span className="instruction__item__count__number">1</span>
                        </div>
                        <div className="instruction__item__info" data-aos="fade-up">
                            <h3 className="instruction__item__info__title">Шаг первый</h3>
                            <span className="instruction__item__info__text">Сообщите работнику фуд-корта о том, что у вас есть карта VISA Альфа Банка (физическая карта VISA Альфа Банка, в Pay-cервисе или приложении INSNC).</span>
                        </div>
                    </div>
                    <div className="instruction__item" data-aos="zoom-in">
                        <div className="instruction__item__count">
                            <span className="instruction__item__count__number">2</span>
                        </div>
                        <div className="instruction__item__info" data-aos="fade-up">
                            <h3 className="instruction__item__info__title">Шаг второй</h3>
                            <span className="instruction__item__info__text">Оплатите покупку картой со скидкой 10%.</span>
                        </div>
                    </div>
                    <div className="instruction__item" data-aos="zoom-in">
                        <div className="instruction__item__count">
                            <span className="instruction__item__count__number">3</span>
                        </div>
                        <div className="instruction__item__info" data-aos="fade-up">
                            <h3 className="instruction__item__info__title">Шаг третий</h3>
                            <span className="instruction__item__info__text">Наслаждайтесь!</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InstructionBlock;