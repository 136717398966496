import AdvantagesBlocks from './AdvantagesBlocks';
import BottomAlfa from './BottomAlfa';
import ImportantBlock from './ImportantBlock';
import InstructionBlock from './InstructionBlock';
import NavigationAlfa from './NavigationAlfa';
import './style.scss';
import TopAlfa from './TopAlfa';

import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';

import FormModal from "../applications/FormModal";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FAQ from './FAQItem';
import FaqBlock from './FaqBlock';


const Alfabank = () => {

    const { isOpenModal } = useAppSelector((state: RootState) => state.applicationsStore);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, [pathname]);

    return(
        <div className={isOpenModal ? 'open-form' : ''}>
            <TopAlfa />
            <FaqBlock />
            <NavigationAlfa />
            <AdvantagesBlocks />
            <InstructionBlock />
            <ImportantBlock />
            <BottomAlfa />
            <FormModal />
        </div>
    );
}

export default Alfabank;