import './style.scss';
import cardSimple from '../../../img/alfabank/card-simple.png';
import cardPremium from '../../../img/alfabank/card-premium.png';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { openModal } from '../../../redux/slices/applicationsSlice';

const AdvantagesBlocks = () => {
    useEffect(() => {
        if (window.innerWidth < 768) {
          AOS.init();
        }
      }, []);
      const dispatch = useAppDispatch();
    return(
        <div className="advantages">
            <div className="advantages__container">
                <div className="advantages__wrapper">
                    <div className="advantages__item" data-aos="zoom-in">
                        <h3 className="advantages__item__title">Держатели ВСЕХ* карт Visa Альфа Банка:</h3>
                        <ul className="advantages__item__list">
                            <li className="advantages__item__list__item">вернут 10% бонусами за покупку билетов на официальном сайте фестиваля. Так можно вернуть до 50 BYN!*</li>
                            <li className="advantages__item__list__item">получат отдельный вход на фестиваль</li>
                            <li className="advantages__item__list__item">получат скидку 10% на всех фуд-кортах фестиваля</li>
                        </ul>
                        <p className="advantages__item__note">*Участвуют карты Visa в BYN, включенные в «Пакет решений», за исключением кредитных, бонусных карт и карт с услугой Альфа Белавиа.</p>
                        <div className="advantages__item__photo">
                            <img src={cardSimple} alt="Alfabank Viva Braslav" className="advantages__item__photo__img" />
                        </div>
                    </div>
                    <div className="advantages__item" data-aos="zoom-in">
                        <h3 className="advantages__item__title">Держатели ПРЕМИАЛЬНЫХ карт Visa Альфа Банка:</h3>
                        <ul className="advantages__item__list">
                            <li className="advantages__item__list__item">получат полёт на аэростате со скидкой 50%, при оплате премиальной картой Platinum, Signature и Infinite.</li>
                        </ul>
                        <p className="advantages__item__note">Чтобы получить скидку 50% для полёта на аэростате — оплатите полёт премиальной картой Platinum, Signature и Infinite Visa Альфа Банка.</p>
                        <div className="advantages__item__photo">
                            <img src={cardPremium} alt="Alfabank Viva Braslav" className="advantages__item__photo__img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdvantagesBlocks;