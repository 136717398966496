import './style.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const BottomAlfa = () => {
    useEffect(() => {
        AOS.init();
      }, [])
    return(
        <div className="footer-alfabank" data-aos="zoom-in" id='get'>
            <div className="footer-alfabank__container">
                <div className="footer-alfabank__wrapper">
                    <span className="footer-alfabank__text">Оформляйте карты Visa Альфа Банка и отдыхайте с выгодой на Viva&nbsp;Braslav&nbsp;2025.</span>
                    <a href="https://www.alfabank.by/viva-braslav/?utm_source=website&utm_medium=vivabraslav.by&utm_campaign=alfabonus_vivabraslav&utm_content=banner" target="_blank" rel="noreferrer" className="footer-alfabank__button">Оформить</a>
                </div>
            </div>
        </div>
    );
}

export default BottomAlfa;