import PageTitle from "../../Components/pageTitle";
import "../returns/style.scss";

const CompanyTickets = () => {
    return (
        <>
            <PageTitle title="Билеты" subtitle="для юридических лиц" />
            <div className="returns">
                <div className="returns_container">
                    <div className="returns_wrapper">
                        <h1 className="returns_title">
                            Приобретение билетов
                        </h1>
                        <span className="returns_info">
                            Для приобретения билетов на фестиваль Viva Braslav 2025 Вам необходимо:
                        </span>
                        <ol className="returns_list">
                            <li>
                                Ознакомиться с{" "}
                                <a
                                    href="https://vivabraslav.by/docs/2025/legal_entity/public_contract.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Договором публичной оферты для юридических
                                    лиц
                                </a>
                                .
                            </li>
                            <li>
                                Скачать <a
                                    href="https://vivabraslav.by/docs/2025/legal_entity/legal_entity_tickets.docx"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    ЗАЯВКУ
                                </a> на оформление билетов с
                                оплатой по безналичному расчету и заполнить ее.
                            </li>
                            <li>
                                Отправить заполненную заявку на адрес
                                электронной почты{" "}
                                <a href="mailto:sas@levol.by">sas@levol.by</a>.
                            </li>
                        </ol>

                        <h1 className="returns_title">Возврат билетов</h1>
                        <span className="returns_info">
                            Вернуть билеты на фестиваль можно не позднее, чем за
                            1 календарный день до дня проведения мероприятия
                            (если иной срок не предусмотрен законодательством).
                            Возврат осуществляется в соответствии со статьей 217
                            Кодекса Республики Беларусь о культуре. Если речь
                            идет о возврате билетов других категорий, средства
                            возвращаются в течение 14 дней с момента подачи
                            соответствующего требования, согласно ст. 38-1
                            Закона о защите прав потребителей. Обратите
                            внимание: стоимость сервисного сбора за работу
                            сервиса, начисляемого билетным оператором, возврату
                            не подлежит. <br/><br />Для оформления возврата:
                        </span>
                        <ol className="returns_list">
                            <li>
                                Юридическое лицо заполняет форму{" "}
                                <a
                                    href="https://vivabraslav.by/docs/2025/legal_entity/legal_entity_refunds.docx"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Заявления для возврата билетов
                                </a>.
                            </li>
                            <li>
                                Направляет его по адресу электронной почты{" "}
                                <a href="mailto:sas@levol.by">sas@levol.by</a>.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CompanyTickets;
