import "../style_form.scss";
import FormHeader from "../FormComponents/FormHeader";
import {
    Button,
    Typography,
} from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import cover from '../../../img/applications/goroshek.jpg';
import { useAppDispatch } from '../../../app/hooks';
import { openModal } from '../../../redux/slices/applicationsSlice';


const GoroshekForm = () => {
    const applicationName = "";
    const dispatch = useAppDispatch();

    const getStart = () => {
        return (
            <div className="application-modal_step" key={0}>
                <Typography
                    variant="h6"
                    textAlign="center"
                    marginBottom="5px"
                    marginTop="45px"
                    maxWidth="450px"
                    margin="20px auto 0 auto"
                    >
                    Заполнить и подать заявку на отбор для участия в зоне ремесленников на фестивале Viva Braslav 2025,  вы можете пройдя на официальный сайт по ссылке ниже
                </Typography>
                
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.open('https://forms.yandex.ru/cloud/6782f428d04688b5e61ac61f/', '_blank')}
                    style={{ margin: "20px auto 0 auto", maxWidth: "300px" }}
                >Открыть заявку</Button>
            </div>
        );
    };

    return (
        <div className="application-modal_wrapper">
            <FormHeader applicationName={applicationName} />
            {getStart()}
        </div>
    );
};

export default GoroshekForm;
