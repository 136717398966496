import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { FormControl, TextField, Divider, Typography, Box, Stack, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import InputMask from "react-input-mask";
import dayjs from "dayjs";
import "dayjs/locale/ru";

const StepTwoChild = ({ register, errors, control, handleSet, fields, setError }) => {
    const { getValues } = useFormContext();
    const isFamily = getValues("type") === 'sport-1';

    dayjs.locale("ru");
    const maxDate = dayjs("2017-07-27");

    const validateDate = (value) => {
        const regex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/;
        if (!regex.test(value)) {
            return "Введите корректную дату в формате ДД.ММ.ГГГГ";
        }
        const [day, month, year] = value.split('.').map(Number);
        const enteredDate = dayjs(`${year}-${month}-${day}`);
        if (!enteredDate.isValid()) {
            return "Введите корректную дату";
        }
        if (enteredDate.isAfter(maxDate)) {
            return `Дата должна быть не позже ${maxDate.format('DD.MM.YYYY')}`;
        }
        return true;
    };

    return (
        <div className="application-modal_step" key={0}>
            <Box sx={{ mb: 1 }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Typography gutterBottom variant="h6" component="div">
                        Данные ребёнка
                    </Typography>
                </Stack>
                <Typography variant="body2">
                    Ребёнок должен быть старше 7 лет
                </Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Фамилия Имя Отчество:
                </span>
                <TextField
                    size="small"
                    {...register("childPerson", {
                        required: isFamily,
                        validate: (value) => {
                            const words = value.trim().split(/\s+/);
                            return (
                                words.length === 3 ||
                                "Укажите, пожалуйста, ФИО полностью"
                            );
                        },
                    })}
                    error={!!errors.childPerson}
                    helperText={errors.childPerson?.message}
                />
            </FormControl>
            
            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Пол:
                </span>
                <Controller
                    name="childSex"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value="М"
                                control={<Radio />}
                                label="Мужской"
                            />
                            <FormControlLabel
                                value="Ж"
                                control={<Radio />}
                                label="Женский"
                            />
                        </RadioGroup>
                    )}
                />
                {errors.childSex && (
                    <span className="application-modal_question_error">
                        Обязательное поле
                    </span>
                )}
            </FormControl>

            <FormControl className="application-modal_question">
                <span className="application-modal_question_title">
                    Дата рождения:
                </span>
                <Controller
                    name="childBirthday"
                    control={control}
                    rules={{ 
                        required: isFamily,
                        validate: validateDate
                    }}
                    render={({ field }) => (
                        <InputMask
                            {...field}
                            mask="99.99.9999"
                            maskChar={null}
                        >
                            {(inputProps) => (
                                <TextField
                                    {...inputProps}
                                    size="small"
                                    placeholder="ДД.ММ.ГГГГ"
                                    error={!!errors.childBirthday}
                                    helperText={errors.childBirthday?.message}
                                />
                            )}
                        </InputMask>
                    )}
                />
            </FormControl>
        </div>
    );
};

export default StepTwoChild;
